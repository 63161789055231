<script setup>
// import Header from "./Header.vue"
// import MemberInformationBox from "./MemberInformationBox.vue"
// import axios from "axios"
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import BaseButton from "./UI/BaseButton.vue";
import BaseInput from "./UI/BaseInput.vue";
import ButtonSubmit from "./ButtonSubmit.vue";
import ButtonCancel from "./ButtonCancel.vue";
import api from "../api/api.js";
import { flashMessage } from "../functions.js";
</script>

<template>
  <div class="body-container">
    <header>
      <h2>Member Information</h2>
      <div>
        <div style="display: inline-block; margin-right: 10px" class="form">
          <BaseButton
            class="btn-submit"
            @click="mnetapplicationpop"
            :disabled="false"
            v-if="authStatus_num === '1' || authStatus_num === '3'"
            ><a style="color: white; text-decoration: none;" id="screen-pop">Go to Account</a></BaseButton
          >
          <BaseButton
            v-else
            class="btn-submit"
            @click="mnetapplicationpop"
            :disabled="true"
            >Go to Account</BaseButton
          >
        </div>

        <h2 style="display: inline-block; margin-right: 280px">M01</h2>
      </div>
    </header>

    <div v-if="callerInfo && callerInfo.result" class="member-info">
      <span>
        <div>
          <span>Member Number: </span>
          <span id="label_member_number">{{
            callerInfo.result.MemberNumber || ""
          }}</span>
          <span v-if="callerInfo.result.MemberName && callerInfo.result.MemberNumber && callerInfo.result.MemberNumber.trim() !== ''">
            <img
              class="membernumber-icon"
              title="Valid Member Number"
              :src="logocheck"
              alt="check"
            />
          </span>

          <img
           v-if="!callerInfo.result.MemberName && callerInfo.result.MemberNumber && callerInfo.result.MemberNumber.trim() !== ''"
            class="membernumber-icon"
            title="Invalid Member Number"
            :src="logoclose"
            alt="invalid"
          />
        </div>
        <div>
          <span>Name: </span>
          <span id="label_member_name">{{
            callerInfo.result.MemberName || ""
          }}</span>
        </div>
      </span>

      <span style="max-width: 45%">
        <div>
          <span>Status: </span>
          <b
            ><span id="authstatus_value" :style="{ color: authStatusColor }">{{
              authStatus
            }}</span></b
          >

          <span v-if="authStatus_num === '0'" title="Not Yet Authenticated">
            <img class="membernumber-icon" :src="logoclose" alt="invalid" />
          </span>

          <span
            v-if="authStatus_num === '1'"
            title="Authenticated Valid Credential"
          >
            <img class="membernumber-icon" :src="logocheck" alt="check" />
          </span>

          <span
            v-if="authStatus_num === '2' && !callerInfo.result.FailureReason"
            title="Invalid Credential"
          >
            <img class="membernumber-icon" :src="logominus" alt="invalid" />
          </span>

          <span
            v-show="authStatus_num === '2' && callerInfo.result.FailureReason"
            :title="callerInfo.result.FailureReason"
          >
            <img class="membernumber-icon" :src="blackminus" alt="invalid" />
          </span>

          <span v-if="authStatus_num === '3'" title="Manually Authenticated">
            <img class="membernumber-icon" :src="logocheck" alt="check" />
          </span>

          <span v-if="authStatus_num === '4'" title="Manually Failed">
            <img class="membernumber-icon" :src="logoclose" alt="invalid" />
          </span>

          <span v-if="authStatus_num === '5'" title="Invalid Status">
            <img class="membernumber-icon" :src="logoclose" alt="invalid" />
          </span>
        </div>
        <div>
          <span>Reason: </span>
          <span id="label_attempttype">{{ attemptType }}</span>
        </div>
        <div :style="{ padding: '10px 0', height: phonenumList ? 'auto' : '50px', width: '77%' } ">
          <span id="phonenum_list" v-html="phonenumList"></span>
        </div>
      </span>

      <span style="margin-left: auto">
        <!-- <div class="container">
          <div class="loader-overlay" v-if="isLoading"></div>

          <div class="form">
            <BaseInput
              type="text"
              style="max-width: 50px; padding-left: 5px; font-size: 15px"
              v-model="authToken" />
            <BaseButton class="btn-submit" @click="GetCallerInfoUsingAuthToken"
              >Get Caller Info</BaseButton
            >
          </div>
          <div class="form">
            <BaseButton class="btn-submit" @click="mnetapplicationpop">Show App</BaseButton>
          </div>
        </div> -->
      </span>
    </div>

    <div class="member-info-content">
      <!-- Changed the label to Radio Button - MS05212024 -->
      <div>
        <form @submit.prevent="handleSendRadio()" method="post">
          <div class="radiolist">
            <div style="display: flex;  margin-top: 13px;">
              <input type="radio" id="account_number" name="member_type" value="account_number" v-model="selectedMemberType">
              <label for="account_number">Account Number</label>
            </div>
            <div style="display: flex;  margin-top: 13px;">
              <input type="radio" id="ssn" name="member_type" value="ssn" v-model="selectedMemberType">
              <label for="ssn">SSN</label>
            </div>
          </div>
          <div>
            <BaseInput type="text" id="member_number_or_credit_card" name="member_number_or_credit_card" v-model="inputNo"
              class="form-control" style="padding-left: 10px; font-size: 16px; margin-top: 3px; width: 260px" :disabled="!selectedMemberType" />
          </div>
            <ButtonSubmit style="margin-top: 13.5px" label="Send" :disabled="isSendButtonDisabled" />
            <ButtonCancel style="margin-top: 10px" type="button" label="Cancel" @click="handleCancel('membernumber')"
              :disabled="!inputNo" />
        </form>
        <!-- End of Radio Button -->
        <div style="margin-top: 30px">
          <table id="table" class="member-info-table">
            <caption>
              Member Information
            </caption>
            <thead>
              <tr>
                <th>Name</th>
                <th>Relationship</th>
              </tr>
            </thead>
            <tbody style="display: block; max-height: calc(5 * 2.5em); overflow-y: auto;">
              <!-- <tr>
                <td
                  v-if="
                    callerInfo &&
                    callerInfo.result &&
                    callerInfo.result.MemberName && callerInfo.result.MemberName.trim() !== ''
                  "
                >
                  {{ callerInfo.result.MemberName }}
                </td>
                <td
                  v-if="
                    callerInfo &&
                    callerInfo.result &&
                    callerInfo.result.MemberName && callerInfo.result.MemberName.trim() !== ''
                  "
                >
                  Primary
                </td>
              </tr> -->
              <template v-if="callerInfo && callerInfo.result && callerInfo.result.Accounts">
              <tr v-for="(account, index) in callerInfo.result.Accounts.sort((a, b) => {
                    if (a.type < b.type) return 1;
                    if (a.type > b.type) return -1;
                    return 0;
                  })" :key="index">

              <td v-if="account.Name && account.Name.trim() !== ''">
               {{ account.Name.toUpperCase() }}
              </td>
              <td v-if="account.Name && account.Name.trim() !== ''">
               {{ account.type }}
              </td>
              </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!-- <div v-if="isDemo" style="margin-top: 20px;"> -->
        <!-- <div style="margin-top: 20px">
          <h4>Fraud Warnings</h4>

          <textarea
            v-if="isGetThreshold"
            disabled
            class="form-control-textarea"
            style="font-size: 16px; width: 380px; color: red"
            v-model="threshold"
            cols="15"
            rows="5"
          ></textarea>

          <textarea
            v-else
            disabled
            class="form-control-textarea"
            style="font-size: 16px; width: 380px; color: red"
            cols="15"
            rows="5"
          ></textarea>
        </div> -->
      </div>

      <div>
        <div>
          <form method="post">
            <div>
              <h4>Authentication Status</h4>
              <div class="btn-auth-group" style="margin-top: 10px">
                <button
                  class="btn-manually-auth"
                  :class="{ 'btn-clicked-green': AuthclickedButton === 3 }"
                  @click.prevent="passValueAuth(3, $event)"
                  :disabled="
                    !callerInfo.result.MemberNumber ||
                    Object.keys(callerInfo.result).length === 0
                  "
                >
                  <span class="btn-text-large">Manually Authenticate</span>
                </button>
                <button
                  class="btn-manually-failed"
                  :class="{ 'btn-clicked-red': AuthclickedButton === 4 }"
                  @click.prevent="passValueAuth(4, $event)"
                  :disabled="
                  !callerInfo.result.MemberNumber ||
                  Object.keys(callerInfo.result).length === 0"
                >
                  <span class="btn-text-large">Manually Fail</span>
                </button>
              </div>
            </div>

            <div v-if="isLoadingAuth">
              <clip-loader
                :loading="true"
                :color="setColor"
                :size="setSize"
              ></clip-loader>
            </div>
            <ButtonSubmit
              style="margin-top: 10px"
              v-if="!isLoadingAuth"
              class="btn-submit"
              type="submit"
              label="Save"
              @click.prevent="handleAuth()"
              :disabled="!isAuthVerifed"
            />
            <ButtonCancel
              style="margin-top: 10px"
               v-if="!isLoadingAuth"
              label="Cancel"
              type="button"
              @click="handleCancel('authstatus')"
              :disabled="!isAuthVerifed"
            />
          </form>
        </div>
        <div>
          <form @submit.prevent="handleNote()" style="margin-top: 2.7rem">
            <h4>Security Notes</h4>
            <div v-if="isLoadingNotes">
              <clip-loader
                :loading="true"
                :color="setColor"
                :size="setSize"
              ></clip-loader>
            </div>
            <div style="margin: 10px 0 15px" v-if="!isLoadingNotes">
              <select
                class="select-categories form-control-select"
                name="category"
                v-model="category"
              >
                <option value="" disabled selected hidden>Note Category</option>
                <option
                  v-for="category in noteCategories"
                  :key="category"
                  :value="category"
                >
                  {{ category }}
                </option>
              </select>
            </div>
            <div>
              <label for="">Notes</label>
              <textarea
                class="form-control-textarea"
                style="padding-left: 10px; font-size: 16px; width: 380px"
                v-model="notes"
                cols="15"
                rows="5"
              ></textarea>
            </div>
            <!-- <BaseButton style="margin-top: 5px" class="btn-submit" :isDisabled="isSaveEnabled">Save</BaseButton> -->
            <ButtonSubmit
              style="margin-top: 10px"
              label="Save"
              :disabled="isSaveEnabled"
            />
            <ButtonCancel
              type="button"
              style="margin-top: 10px"
              label="Cancel"
              @click="handleCancel('notecategory')"
              :disabled="isSaveEnabled"
            />
          </form>
        </div>
      </div>

      <!-- Remove this section as per instruction -->
      <!-- <div style="margin-top: 155px">
        <div class="warning-code-col">
          <table id="table" class="warning-code-table">
            <caption>
              Warning Code
            </caption>
            <thead>
              <tr>
                <th style="min-width: 110px">Warning Code</th>
                <th>Description</th>
              </tr>
            </thead> -->
      <!-- <div v-if="isLoadingWarningCode">
            <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
          </div> -->
      <!-- <tbody
              v-if="
                symitarWarningCodes &&
                symitarWarningCodes.length &&
                !isLoadingWarningCode
              "
            >
              <tr
                v-for="codesGroup in symitarWarningCodes"
                :key="codesGroup[0]?.WarningNumber"
              >
                <td
                  style="text-align: right; width: 40px"
                  v-if="codesGroup && codesGroup.length && codesGroup[0]"
                >
                  {{ codesGroup[0].WarningNumber }}
                </td>
                <td v-if="codesGroup && codesGroup.length && codesGroup[0]">
                  {{ codesGroup[0].WarnDescription }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

    </div>
    <!-- modal mnet core client  -->
    <div class="modal" v-show="isOpenMnet" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img class="modal-image" :src="logoPath" alt="MNET Core Access" />
      </div>
    </div>

    <!-- 2FA Section - MS05162024 -->
      <!-- Start of 2FA Feature Code -->

      <div class="flex-container">
        <div class="two-fa-container">
        <h4 class="two-fa-label">2FA</h4>
          <div class="flex">
            <div style="margin: 10px 0 15px">

                        <!-- <select v-model="selectedCountryCode" style="margin-right: 10px;">
              <option value="" disabled selected hidden>Select Country Code</option>
              <option v-for="country in countryCodes" :key="country.code" :value="country.code">
                {{ country.code }}
              </option>
            </select> -->

            <!-- Phone Number Dropdown -->
            <select
              class="select-categories form-control-select"
              name="phoneSendOTP"
              v-model="phoneSendOTP"
              style="font-size: 16px; margin-bottom: 12px; width: 210px"
            >
              <option value="" disabled selected>
                Select Phone Number
              </option>
				<option
				v-for="(phone, index) in cellPhones"
				:key="index"
				:value="phone"
				:style="{ color: '#48b02c' }">
					{{ formatPhoneNumber(phone) }}
				</option>
            </select>

            <!-- Button to Send SMS -->
            <ButtonSubmit
              @click="sendSMS"
              label="Send SMS with OTP"
              style="align-self: flex-start; margin-bottom: 4px"
              :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''"
            />
            </div>
            <!-- OTP Validation Section -->
            <div class="otp-container">
              <BaseInput
                type="number"
                v-model="otpInput"
                placeholder="OTP"
                style="font-size: 16px; width: 140px"
              />
              <div v-if="verificationAttempted" class="verification-status">
                <img
                  v-if="isValid"
                  :src="logocheck"
                  alt="check"
                  class="status-icon"
                />
                <img
                  v-else
                  :src="logoclose"
                  alt="invalid"
                  class="status-icon"
                />
              </div>
            </div>
            <ButtonSubmit
              @click="verifyOTP"
              label="Validate"
              style="align-self: flex-start; margin-top: 4px"
              :disabled="!callerInfo.result.MemberNumber || callerInfo.result.MemberNumber.trim() === ''"
            />
          </div>
        </div>
      </div>
      <!-- End of 2FA Feature Section -->
    </div>

</template>

<script>
export default {
  name: "MemberInformation",

  data: () => ({
    company: localStorage.getItem("companyID"),
    setColor: "#007bff",
    setSize: "50px",
    isLoading: true,
    isLoadingWarningCode: true,
    isLoadingNotes: false,
    isLoadingAuth: false,
    phonenumList: "",
    category: "",
    notes: "",
    inputNo: "",
    authToken: localStorage.getItem("authToken"),
    companyID: "",
    authResult: "",
    AuthclickedButton: null,
    userWarningCodes: [],
    symitarWarningCodes: [],
    noteCategories: [], // Store the note categories
    authStatus: "",
    authStatusColor: "",
    attemptType: "",
    authStatus_num: "",
    callerInfo: {
      status: "",
      message: "",
      result: {},
    },
    authCall: {
      status: "",
      message: "",
      result: {},
    },
    updateCall: {
      status: "",
      message: "",
      field_updated: "",
      result: {},
    },
    updateCallerInfo: {
      status: "",
      message: "",
      field_updated: "",
      result: {},
    },
    warningCodes: {
      status: "",
      num_rows: "",
      result: [],
    },
    isOpenMnet: false,
    logoPath: require("@/assets/mnetCoreClient.jpg"),
    logocheck: require("@/assets/check.png"),
    logoclose: require("@/assets/close.png"),
    logominus: require("@/assets/minus.png"),
    blackminus: require("@/assets/blackminus.png"),

    MaxNumCallsCaller: null,
    NumHoursCaller: null,
    isGetThreshold: false,
    isDemo: false,
    callerID: null,
    threshold: "",
    // Adding the data for sending SMS with OTP - MS05142024
    phoneSendOTP: "",
    otpInput: "",
    verificationAttempted: false,
    isValid: false,
    enrolledNumbers: [],
    formatNumber: "",
    // Adding the Radio Function
    selectedMemberType: null,
    // Add Country Codes
    selectedCountryCode: '',
	cellPhones: [],
  }),
  props: {
    userData: Object,
    brandData: Object,
  },
  async mounted() {
    this.setColor = this.brandData.accent_color1;

    if (this.$route.query.AuthToken) {
      this.authToken = this.$route.query.AuthToken;
    } else {
      this.authToken = localStorage.getItem("authToken");
    }
    //await this.isDemoURL()
    await this.GetCallerInfoUsingAuthToken();
    await this.getThreshold();
    await this.getThresholdCounter();
    await this.fetchNotesCategory();
  },
  computed: {
    /* filteredWarningCodes() {
      return this.warningCodes.result.filter((code) => this.userWarningCodes.includes(code.WarningCode))
    },*/
    // getJointOwners() {
    //   return this.callerInfo.result.Accounts.JointOwnership.map(owner => ({
    //     Name: owner.Name
    //   }))
    // },

    // Adding the Radio Function Mark May 21, 2024

    isSendButtonDisabled() {
      return !this.inputNo || !this.selectedMemberType;
    },

    // Until Here Mark May 21, 2024

    isSendVerified() {
      return (
        !this.inputNo.trim() || Object.keys(this.callerInfo.result).length === 0
      );
    },
    isAuthVerifed() {
      return this.AuthclickedButton === 3 || this.AuthclickedButton === 4;
    },
    isSaveEnabled() {
      if (Object.keys(this.callerInfo.result).length <= 0) {
        return true;
      } else {
        if (this.category || this.notes) {
          return false;
        }
      }
      return true;
    },

  },
  methods: {
    isMemberNameExist(memberName) {
      return (
        this.callerInfo.result &&
        this.callerInfo.result.MemberName &&
        this.callerInfo.result.MemberName.toUpperCase() === memberName
      );
    },

    async isDemoURL() {
      const currentURL = window.location.href;
      const baseURL = currentURL.split("#")[0].replace(/\/$/, ""); // Remove trailing slash

      if (this.authToken) {
        // if (baseURL == "http://localhost:8080") {
        if (
          baseURL == "https://demo38web.core-access.com" ||
          baseURL == "https://sikorskyweb.core-access.com"
        ) {
          this.isDemo = true;
        } else {
          this.isDemo = false;
        }
      }
    },
    async getThreshold() {
      try {
        const result = await api.get(`update_threshold/${this.company}`);

        if (result.data.length > 0) {
          this.MaxNumCallsCaller = result.data[0].MaxNumCallsCaller;
          this.NumHoursCaller = result.data[0].NumHoursCaller;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getThresholdCounter() {
      try {
        const computedNumHoursCaller = this.NumHoursCaller;
        const phoneNumberWithoutTel = this.callerID.replace(/^tel:/, "");

        const data = {
          Company: this.company,
          CallerID: phoneNumberWithoutTel,
          NumHoursCaller: computedNumHoursCaller,
        };
        // const result = await api.post(`http://localhost:443/get-threshold-counter`, data)
        const result = await api.post(`get-threshold-counter`, data);

        if (result.data.length > 0) {
          // if (result.data[0].countIndex == 0) {
          if (result.data[0].countIndex >= this.MaxNumCallsCaller) {
            if (this.authToken) {
              this.threshold = `Global Warning: This caller ID has called ${this.MaxNumCallsCaller} or more times in the last ${this.NumHoursCaller} hours.`;
              this.isGetThreshold = true;
            } else {
              this.isGetThreshold = false;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    closeModal() {
      this.isOpenMnet = false;
    },
    async mnetapplicationpop() {
       const memberNumber = this.callerInfo.result.MemberNumber;
		document.getElementById("screen-pop").href = "mnetpop:" + memberNumber;
     },
    episyspop() {
      const memberNumber = localStorage.getItem("MemberNumber") || "";
      window.location.href = `EpisysPop:${memberNumber}`;
    },

  //  Copy the function for Radio Button from Sikorsky Project
    async handleSendRadio() {
		this.phoneSendOTP = '';
      try {
        if (this.selectedMemberType === 'ssn') {

          const res = await api.get(`/get-account-ssn?company=${this.company}&authToken=${this.authToken}&taxId=${this.inputNo}`);
    await this.GetCallerInfoUsingAuthToken();
          if (res.status === 200 && res.data.Response == true) {

              this.callerInfo.result.Accounts = res.data.Accounts;
              this.callerInfo.result.MemberNumber = res.data.MemberNumber;
              this.callerInfo.result.MemberName = res.data.MemberName;
              this.callerInfo.result.PhoneNumbers = res.data.PhoneNumbers;
              this.callerInfo.result.AttemptType = res.data.AttemptType;
              if (process.env.VUE_APP_IS_SANTA_CLARA==='yes') {
                  this.readCallerAuthData(
                "6",
                "3",
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }
              else {
                this.readCallerAuthData(
                this.authStatus_num,
                this.callerInfo.result.AttemptType,
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }

              console.log('attemptNum after');
              flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Updating caller info based on Tax ID."
            )
          }
          else{

			this.callerInfo.result.Accounts = [];
              this.callerInfo.result.MemberNumber = '';
              this.callerInfo.result.MemberName = '';
              this.callerInfo.result.PhoneNumbers = { cellPhones: [], other: [] };

              if (process.env.VUE_APP_IS_SANTA_CLARA==='yes') {
                this.readCallerAuthData(
                  "6",
                  "3",
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }
              else {
                this.readCallerAuthData(
                this.authStatus_num,
                this.callerInfo.result.AttemptType,
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }


              flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Member Not found"
              )
          }
        }

        if (this.selectedMemberType === 'account_number') {
          const res = await api.get(`/get-account-number?company=${this.company}&authToken=${this.authToken}&accountNumber=${this.inputNo}`);
          await this.GetCallerInfoUsingAuthToken();
          if (res.status === 200 && res.data.Response == true) {

              this.callerInfo.result.Accounts = res.data.Accounts;
              this.callerInfo.result.MemberNumber = res.data.MemberNumber;
              this.callerInfo.result.MemberName = res.data.MemberName;
              this.callerInfo.result.PhoneNumbers = res.data.PhoneNumbers;
              this.callerInfo.result.AttemptType = res.data.AttemptType;
              if (process.env.VUE_APP_IS_SANTA_CLARA==='yes') {
                  this.readCallerAuthData(
                "6",
                "3",
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }
              else {
                this.readCallerAuthData(
                this.authStatus_num,
                this.callerInfo.result.AttemptType,
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }
              flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Updating caller info based on Account Number."
            )
          }
          else{

			this.callerInfo.result.Accounts = [];
              this.callerInfo.result.MemberNumber = '';
              this.callerInfo.result.MemberName = '';
              this.callerInfo.result.PhoneNumbers = { cellPhones: [], other: [] };

              this.callerInfo.result.AttemptType = res.data.AttemptType;
              if (process.env.VUE_APP_IS_SANTA_CLARA==='yes') {
                  this.readCallerAuthData(
                "6",
                "3",
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }
              else {
                this.readCallerAuthData(
                this.authStatus_num,
                this.callerInfo.result.AttemptType,
                this.callerInfo.result.PhoneNumbers,
                this.callerInfo.result.CallerID
                );
              }
              flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Member Not found"
              )
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.inputNo = '';
      this.selectedMemberType = null;
    },
      // End Copy the function for Radio Button from Sikorsky Project

    async handleSend() {
      this.isLoading = true;
      this.isLoadingWarningCode = true;
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      try {
        // PHP START
        // const resCallerInfo = await axios.get(
        //   `https://genesys-api.gcm3.com/apiNODE/api.php?action=get_caller_info_by_id&IndexNum=${this.authToken}&company=1285`,
        //   { headers }
        // )
        // if (resCallerInfo.status === 200) {
        //   this.isLoading = false
        //   this.symitarWarningCodes.splice(0, this.symitarWarningCodes.length)
        //   this.callerInfo.status = resCallerInfo.data.status
        //   this.callerInfo.message = resCallerInfo.data.message
        //   this.authResult = await this.authenticateMethod(
        //     this.inputNo.length,
        //     this.inputNo,
        //     resCallerInfo.data.result.CallerID,
        //     this.authToken,
        //     this.companyID
        //   )
        //   if (this.authResult.Response == "YES") {
        //     const resCallerInfoUpdated = await axios.get(
        //       `https://genesys-api.gcm3.com/apiNODE/api.php?action=get_caller_info_by_id&IndexNum=${this.authToken}&company=1285`,
        //       { headers }
        //     )
        //     this.callerInfo.result = resCallerInfoUpdated.data.result
        //     this.readCallerAuthData(
        //       this.callerInfo.result.AuthStatus,
        //       this.callerInfo.result.AttemptType,
        //       this.callerInfo.result.PhoneNumbers,
        //       this.callerInfo.result.CallerID
        //     )
        //     localStorage.setItem("MemberNumber", this.callerInfo.result.MemberNumber)
        //     this.userWarningCodes = resCallerInfo.data.result.WarningCodes
        //     this.inputNo = ""
        //     if (this.inputNo.length === 16) {
        //       flashMessage(
        //         this.$flashMessage,
        //         this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //         this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //         "Updating caller info based on debit card number."
        //       )
        //     } else if (this.inputNo.length !== 9) {
        //       flashMessage(
        //         this.$flashMessage,
        //         this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //         this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //         "Updating caller info based on member number."
        //       )
        //     }

        //     if (this.authResult.WarningCodes) {
        //       // Populate warning codes
        //       // Loop through WarningCodes and populate each one
        //       const warningCodesArray = this.callerInfo.result.WarningCodes.split(",")
        //       // Inside the loop, update the code to assign the result directly to this.symitarWarningCodes
        //       for (const code of warningCodesArray) {
        //         await this.populateWarningCodes(code)
        //       }
        //       this.isLoadingWarningCode = false
        //     }
        //   } else if (this.authResult.Response == "NO") {
        //     flashMessage(
        //       this.$flashMessage,
        //       this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //       this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //       "Member number not found."
        //     )

        //     this.isLoadingWarningCode = false
        //   } else {
        //     flashMessage(
        //       this.$flashMessage,
        //       this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //       this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //       "Member number not found."
        //     )
        //     this.isLoadingWarningCode = false
        //   }
        // } else {
        //   flashMessage(
        //     this.$flashMessage,
        //     this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        //     this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        //     "No response from Authenticate.php endpoint."
        //   )
        // }

        // NODE START
        const resCallerInfo = await api.get(
          `GetCallerInfo/${this.authToken}/${this.company}`
        );

        if (resCallerInfo.status === 200) {
          this.callerID = resCallerInfo.data.CallerID;
          this.isLoading = false;
          this.symitarWarningCodes.splice(0, this.symitarWarningCodes.length);
          this.callerInfo.status = resCallerInfo.status;
          // this.callerInfo.message = resCallerInfo.data.message

          // NEED VPN to open
          this.authResult = await this.authenticateMethod(
            this.inputNo.length,
            this.inputNo,
            resCallerInfo.data.CallerID,
            this.authToken,
            this.companyID
          );
          if (this.authResult.Response == "YES") {
            const resCallerInfoUpdated = await api.get(
              `GetCallerInfo/${this.authToken}/${this.company}`
            );

            this.callerInfo.result = resCallerInfoUpdated.data;
            //CHECKPOINT

            this.readCallerAuthData(
              this.callerInfo.result.AuthStatus,
              this.callerInfo.result.AttemptType,
              this.callerInfo.result.PhoneNumbers,
              this.callerInfo.result.CallerID,

            );

            localStorage.setItem(
              "MemberNumber",
              this.callerInfo.result.MemberNumber
            );

            this.userWarningCodes = resCallerInfo.data.WarningCodes;
            this.inputNo = "";
            if (this.inputNo.length === 16) {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#FF7F50",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                "Updating caller info based on debit card number."
              );
            } else if (this.inputNo.length !== 9) {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1
                  ? this.brandData.accent_color1
                  : "#FF7F50",
                this.brandData.flash_text_color
                  ? this.brandData.flash_text_color
                  : "#ffffff",
                "Updating caller info based on member number."
              );
            }

            if (this.authResult.WarningCodes) {
              // Populate warning codes
              // Loop through WarningCodes and populate each one
              const warningCodesArray =
                this.callerInfo.result.WarningCodes.split(",");
              // Inside the loop, update the code to assign the result directly to this.symitarWarningCodes
              for (const code of warningCodesArray) {
                await this.populateWarningCodes(code);
              }
              this.isLoadingWarningCode = false;
            }
          } else if (this.authResult == "NO") {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#FF7F50",
              this.brandData.flash_text_color
                ? this.brandData.flash_text_color
                : "#ffffff",
              "Member number not found."
            );

            this.isLoadingWarningCode = false;
          } else {
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1
                ? this.brandData.accent_color1
                : "#FF7F50",
              this.brandData.flash_text_color
                ? this.brandData.flash_text_color
                : "#ffffff",
              "Member number not found."
            );
            this.isLoadingWarningCode = false;
          }
        } else {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "No response from Authenticate.php endpoint."
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    // NEXT
    async handleAuth() {
      this.isLoadingAuth = true;
      this.isLoading = true;
      try {

        let FailureReason = "";
        let SuccessOrFail = "";

        if (this.AuthclickedButton === 3) {
          // Handle Manually Authenticated logic
          FailureReason = null;
          SuccessOrFail = this.AuthclickedButton.toString();
        } else if (this.AuthclickedButton === 4) {
          // Handle Manually Failed logic
          FailureReason = "Manually Failed by Agent";
          SuccessOrFail = this.AuthclickedButton.toString();
        }

        const resCallerInfo = await api.get(
          `GetCallerInfo/${this.authToken}/${this.company}`
        );

        if (resCallerInfo.status === 200) {
          this.callerID = resCallerInfo.data.CallerID;
          this.callerInfo.result = resCallerInfo.data;
          this.authCall = await this.AddAuthCall(
            FailureReason,
            SuccessOrFail,
            this.callerInfo.result
          );

          if (this.authCall.affectedRows === 1) {

            this.updateCall = await this.UpdateCallsByAuthToken(
              FailureReason,
              SuccessOrFail,
              this.callerInfo.result,
              this.authToken
            );
            if (this.updateCall.affectedRows === 1) {
              this.updateCallerInfo = await this.UpdateCallerInfo(
                SuccessOrFail,
                this.authToken
              );

              if (this.updateCallerInfo.affectedRows === 1) {
                const resCallerInfoUpdated = await api.get(
                  `GetCallerInfo/${this.authToken}/${this.company}`
                );

                this.callerInfo.result = resCallerInfoUpdated.data;
                this.callerInfo.result.Accounts = JSON.parse(resCallerInfoUpdated.data.Accounts)
                this.readCallerAuthData(
                  this.callerInfo.result.AuthStatus,
                  this.callerInfo.result.AttemptType,
                  this.callerInfo.result.PhoneNumbers,
                  this.callerInfo.result.CallerID
                );
                this.isLoadingAuth = false;
                this.isLoading = false;
                this.AuthclickedButton = 0;

                flashMessage(
                  this.$flashMessage,
                  this.brandData.accent_color1
                    ? this.brandData.accent_color1
                    : "#FF7F50",
                  this.brandData.flash_text_color
                    ? this.brandData.flash_text_color
                    : "#ffffff",
                  "Authentication status successfully updated."
                );
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleNote() {
      this.isLoading = true;
      try {
        // NODE START
        const resCallerInfo = await api.get(
          `GetCallerInfo/${this.authToken}/${this.company}`
        );

        this.callerInfo.result = resCallerInfo.data;

        const body = {
          Company: this.company,
          Name: this.callerInfo.result.MemberName,
          MemberNumber: this.callerInfo.result.MemberNumber,
          PhoneNumber: this.callerInfo.result.CallerID,
          InteractionId: this.callerInfo.result.InteractionId,
          NoteCategory: this.category,
          NoteText: this.notes,
          CreatedByAgent: this.callerInfo.result.AgentName,
          AgentID: this.callerInfo.result.AgentId,
          AgentName: this.callerInfo.result.AgentName,
        };
        const resAddFraudnotes = await api.post("addFraudNotes", body);

        if (resAddFraudnotes.status === 200) {
          this.isLoading = false;
          this.category = "";
          this.notes = "";

          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Security note successfully added."
          );
          await this.GetCallerInfoUsingAuthToken();
        } else {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Security note not added."
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GetCallerInfoUsingAuthToken() {
   this.isLoading = true;
   this.isLoadingWarningCode = true;
  try {
    const resCallerInfo = await api.get(`GetCallerInfo/${this.authToken}/${this.company}`);

    if (resCallerInfo.status === 200) {

      if (resCallerInfo.data.Accounts !== null) {
        let originalAccounts = [];
        let accountObject = {};
        if (typeof resCallerInfo.data.Accounts === 'undefined') {
          const accountsColumn = resCallerInfo.data.Accounts;
          accountObject = JSON.parse(accountsColumn);
          const accountValue = accountObject.Accounts;
          originalAccounts = accountValue;
        } else {
           originalAccounts = JSON.parse(resCallerInfo.data.Accounts);
        }
        const uniqueNamesSet = new Set();
        let uniqueAccounts = originalAccounts.filter((account) => {
        const upperCaseOwner = account.Name.toUpperCase();
        // Check if the account name is empty, contains only whitespace, or is not a valid name
        const isRealName = upperCaseOwner.trim().length >= 0;
        if (!isRealName) {
            return false;
        }

        if (!uniqueNamesSet.has(upperCaseOwner)) {
        uniqueNamesSet.add(upperCaseOwner + account.Suffix);
        return true;
        }
        return false;
      });
      uniqueAccounts = uniqueAccounts.sort((a, b) => {
              if (a.Name < b.Name) {
                  return -1;
              }
              if (a.Name > b.Name) {
                  return 1;
              }
              return 0;
          });
      if (typeof resCallerInfo.data.Accounts === 'undefined') {
          this.uniqueAccounts = uniqueAccounts;
        } else {
          resCallerInfo.data.Accounts = uniqueAccounts;
          this.uniqueAccounts = resCallerInfo.data.Accounts;
        }
      } else {
        console.log("Accounts data is null.");
      }

      this.callerID = resCallerInfo.data.CallerID;
      this.isLoading = false;
      if (resCallerInfo.status === 200) {
        if (resCallerInfo.data) {
          this.symitarWarningCodes.splice(0, this.symitarWarningCodes.length);
          this.callerInfo.status = resCallerInfo.status;
          this.callerInfo.result = resCallerInfo.data;

          this.readCallerAuthData(
            this.callerInfo.result.AuthStatus,
            this.callerInfo.result.AttemptType,
            this.callerInfo.result.PhoneNumbers,
            this.callerInfo.result.CallerID
          );

          this.userWarningCodes = resCallerInfo.data.WarningCodes;
          localStorage.setItem("authToken", this.authToken);
          localStorage.setItem("MemberNumber", this.callerInfo.result.MemberNumber);
          const warningCodesArray = this.callerInfo.result.WarningCodes ? this.callerInfo.result.WarningCodes.split(',') : [];

          for (const code of warningCodesArray) {
            await this.populateWarningCodes(code);
          }
        }
      }
      this.isLoadingWarningCode = false;
    }
  } catch (error) {
    console.log(error);
  }
},
    async populateWarningCodes(code) {
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }

      try {
        const body = {
          Company: this.company,
          WarningNumber: code,
          AuthToken: this.authToken,
        };
        // /GetWarningSelectFields
        // const resSymitarWarningCodes = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/GetWarningSelectFields.php`,
        //   body,
        //   { headers }
        // )
        const resSymitarWarningCodes = await api.post(
          "GetWarningSelectFields",
          body
        );

        if (resSymitarWarningCodes.status === 200) {
          if (resSymitarWarningCodes.data.Warnings) {
            this.symitarWarningCodes.push(resSymitarWarningCodes.data.Warnings);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchNotesCategory() {
      this.isLoadingNotes = true;
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      try {
        //PHP
        // const resNotesCategory = await axios.get(
        //   "https://genesys-api.gcm3.com/apiNODE/api_notescategory.php?action=get_notes_category_by_id&Company=1285",
        //   { headers }
        // )
        // if (resNotesCategory.status === 200) {
        //   this.noteCategories = resNotesCategory.data.result.map((item) => item.NoteCategory)
        //   console.log("NotesCategory by Company API result = ", resNotesCategory.data.result)
        //   console.log(this.noteCategories)
        //   this.isLoadingNotes = false
        // }
        // NODE START
        const { status, data } = await api.post("getNoteCategory", {
          Company: this.company,
        });

        if (status === 200) {
          this.noteCategories = data.map((item) => item.NoteCategory);
          this.isLoadingNotes = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    passValueAuth(value, event) {
      // Here you can access the passed value and perform any necessary operations
      this.AuthclickedButton = value;

      if (value === 3) {
        // Manually Authenticated
        this.AuthclickedButton = 3;
      } else if (value === 4) {
        // Manually Failed
        this.AuthclickedButton = 4;
      }
      event.preventDefault();
    },

    // Need to Revise this Section to Remove +1 because it's
    // Already in the backend
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (cleaned.length <= 5) {
          return; // Return undefined if length is 5 or below
      }
      if (match) {
        // Always prepend '+1' to the phone number
        let intlCode = "+1 ";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
      }
      return str;
    },
    readCallerAuthData(authStatusNum, attemptNum, PhoneNumbers, CallerID) {
      if (process.env.VUE_APP_IS_SANTA_CLARA==='yes') {
        if (authStatusNum === "0") {
          this.authStatus = "Incorrect Member Information Entered";
          this.authStatusColor = "red";
          this.authStatus_num = "0";
        } else if (authStatusNum === "1") {
          this.authStatus = "Valid Member Information Entered"; // Authenticated
          this.authStatusColor = "green";
          this.authStatus_num = "1";
        } else if (authStatusNum === "2") {
          this.authStatus = "Invalid Member Information Entered"; // Not Authenticated
          this.authStatusColor = "red";
          this.authStatus_num = "2";
        } else if (authStatusNum === "3") {
          this.authStatus = "Manually Authenticated";
          this.authStatusColor = "black";
          this.authStatus_num = "3";
        } else if (authStatusNum === "4") {
          this.authStatus = "Manually Failed";
          this.authStatusColor = "red";
          this.authStatus_num = "4";
        } else if (authStatusNum === "6") { //FOR SANTA CLARA RARE SCENARIO #JEFFERSON20240702
          this.authStatus = "";
          this.authStatusColor = "black";
          this.authStatus_num = "6";
        }
        else {
          this.authStatus = "Invalid Status";
          this.authStatusColor = "red";
          this.authStatus_num = "5";
        }
      }
      else {
          if (authStatusNum === "0") {
          this.authStatus = "Incorrect Member Information Entered";
          this.authStatusColor = "red";
          this.authStatus_num = "0";
        } else if (authStatusNum === "1") {
          this.authStatus = "Valid Member Information Entered"; // Authenticated
          this.authStatusColor = "green";
          this.authStatus_num = "1";
        } else if (authStatusNum === "2") {
          this.authStatus = "Invalid Member Information Entered"; // Not Authenticated
          this.authStatusColor = "red";
          this.authStatus_num = "2";
        } else if (authStatusNum === "3") {
          this.authStatus = "Manually Authenticated";
          this.authStatusColor = "black";
          this.authStatus_num = "3";
        } else if (authStatusNum === "4") {
          this.authStatus = "Manually Failed";
          this.authStatusColor = "red";
          this.authStatus_num = "4";
        } else {
          this.authStatus = "Invalid Status";
          this.authStatusColor = "red";
          this.authStatus_num = "5";
        }
      }
      if (process.env.VUE_APP_IS_SANTA_CLARA==='yes') {
        if (attemptNum === "0") {
          this.attemptType = "Not Attempted";
        } else if (attemptNum === "1") {
          this.attemptType = "Attempted";
        } else if (attemptNum === "2") {
          this.attemptType = "Manually Attempted";
        }
        else if (attemptNum === "3") { //FOR SANTA CLARA RARE SCENARIO #JEFFERSON20240702
          this.attemptType = "";
        }
        else {
          this.attemptType = "Invalid Attempt Value";
        }
      }
      else {
        if (attemptNum === "0") {
          this.attemptType = "Not Attempted";
        } else if (attemptNum === "1") {
          this.attemptType = "Attempted";
        } else if (attemptNum === "2") {
          this.attemptType = "Manually Attempted";
        } else {
          this.attemptType = "Invalid Attempt Value";
        }
      }

      try {
        const formattedCallerID = this.formatPhoneNumber(CallerID);
		let cellPhoneNumberHTML = '';
		PhoneNumbers = PhoneNumbers ? PhoneNumbers : { cellPhones: [], other: [] };

		for (let i = 0; i < PhoneNumbers.cellPhones.length; i++) {
			PhoneNumbers.cellPhones[i] = this.formatPhoneNumber(PhoneNumbers.cellPhones[i]);
		}

		for (let i = 0; i < PhoneNumbers.other.length; i++) {
			PhoneNumbers.other[i] = this.formatPhoneNumber(PhoneNumbers.other[i]);
		}

		const cellPhones = PhoneNumbers.cellPhones;
		this.cellPhones = cellPhones;
    const enrolledNumbers = [...new Set(PhoneNumbers.other)].filter(number => number !== undefined);
    let phonesToCheck = [...enrolledNumbers];
		for (let i = 0; i < cellPhones.length; i++) {
			let cellPhone = cellPhones[i];
      const cellPhonesArray = cellPhone.split(',');
      for (let i = 0; i < cellPhonesArray.length; i++) {
        let cellPhone = cellPhonesArray[i].trim();
        phonesToCheck.push(this.formatPhoneNumber(cellPhone));
        cellPhoneNumberHTML += `<span style="color: green;">${this.formatPhoneNumber(cellPhone)}</span>`;
        // Add a comma after each phone number (except the last one)
        if (i < cellPhonesArray.length - 1) {
            cellPhoneNumberHTML += ', ';
        }
      }
		}

        let otherNumbers = enrolledNumbers.join(', ');
        if (enrolledNumbers.length === 1) {
            otherNumbers = enrolledNumbers[0]; // If only one item, assign it directly without joining
        }
        if (formattedCallerID) {
          if (phonesToCheck.includes(formattedCallerID)) {
            this.phonenumList = `The CallerID, ${formattedCallerID}, is found. <br>Enrolled numbers are: ${cellPhoneNumberHTML}<br>${otherNumbers ? ' ' + otherNumbers : ''}`;
          } else {
			if (phonesToCheck.length > 0) {
				this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. <br>Enrolled numbers are: ${cellPhoneNumberHTML}<br>${otherNumbers ? ' ' + otherNumbers : ''}`;
			} else {
				this.phonenumList = `The CallerID, ${formattedCallerID}, is not found.<br>Phone List is empty`;
			}
          }
        } else {
          if (phonesToCheck.length > 0) {
            this.phonenumList = `The CallerID is empty.<br> Enrolled numbers are: ${cellPhoneNumberHTML}<br>${otherNumbers ? ', ' + otherNumbers : ''}`;
          } else {
            this.phonenumList = "The CallerID is empty and Phone List is empty";
          }
        }
        this.enrolledNumbers = phonesToCheck
      } catch (error) {
        const formattedCallerID = this.formatPhoneNumber(CallerID);
        this.phonenumList = `The CallerID, ${formattedCallerID}, is not found. Phone List is empty`;
        console.error("Error:", error);
      }
    },
    async authenticateMethod(
      charCount,
      inpputedNo,
      callerID,
      authToken,
      companyID
    ) {
      // const headers = {
      //   Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //   "Content-Type": "application/json",
      // }
      // let authenticationMethod = 0
      // let creditCardNumber = null
      let memberNumber = null;
      if (charCount === 16) {
        // authenticationMethod = 2
        // creditCardNumber = inpputedNo
      } else if (charCount !== 9) {
        // authenticationMethod = 0
        memberNumber = inpputedNo;
      } else {
        // creditCardNumber = inpputedNo
      }
      try {
        // const res = await axios.post(
        //   `https://genesys-api.gcm3.com/apiNODE/Authenticate.php`,
        //   {
        //     AuthenticationMethod: authenticationMethod,
        //     CreditCardNumber: creditCardNumber,
        //     MemberNumber: memberNumber,
        //     CallerID: callerID,
        //     AuthToken: authToken,
        //     Company: companyID,
        //   },
        //   { headers }
        // )
        const res = await api.post("MemberAppHandleSend", {
          MemberNumber: memberNumber,
          CallerID: callerID,
          AuthToken: authToken,
          Company: companyID,
        });

        if (res.status === 200) {
          if (res.data.Response == "YES" && res.data.WarningCodes) {
            this.userWarningCodes = res.data.WarningCodes.split(",");
            return res.data;
          } else {
            return res.data;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async AddAuthCall(FailureReason, SuccessOrFail, CallerInfo) {
      try {
        const res = await api.post("insertAuthCalls", {
          MemberNumber: CallerInfo.MemberNumber,
          MemberName: CallerInfo.MemberName,
          CallerID: CallerInfo.CallerID,
          AuthMethod: 2,
          SuccessOrFail: SuccessOrFail,
          Company: this.company,
          InteractionId: CallerInfo.InteractionId,
          FailureReason: FailureReason,
          CreditCardNumber: CallerInfo.CreditCardNumber,
          AgentID: CallerInfo.AgentId,
          AgentName: CallerInfo.AgentName,
        });

        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async UpdateCallsByAuthToken(
      FailureReason,
      SuccessOrFail,
      CallerInfo,
      AuthToken
    ) {
      try {
        const res = await api.post("updateCallsAuthStatus", {
          MemberNumber: CallerInfo.MemberNumber,
          MemberName: CallerInfo.MemberName,
          AuthToken: AuthToken,
          AuthStatus: SuccessOrFail,
          FailureReason: FailureReason,
          AgentID: CallerInfo.AgentId,
          AgentName: CallerInfo.AgentName,
        });

        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async UpdateCallerInfo(SuccessOrFail, AuthToken) {

      try {
        const res = await api.post("updateCallerInfoSetAuthStatus", {
          AuthStatus: SuccessOrFail,
          AttemptType: 2,
          IndexNum: AuthToken,
        });

        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCancel(value) {
      switch (value) {
        case "membernumber":
          this.inputNo = "";
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Member number canceled successfully"
          );
          break;
        case "authstatus":
          this.AuthclickedButton = "";
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Authentication status canceled successfully"
          );
          break;
        case "notecategory":
          this.notes = "";
          this.category = "";
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Note Category canceled successfully"
          );
          break;
      }
      this.inputNo = '';
      this.selectedMemberType = null;
    },


    // Twilio Send SMS With OTP Function - MS05152024
    async sendSMS() {
      try {
        // Format the phone number with the selected country code
        // if (this.selectedCountryCode && this.phoneSendOTP) {
        //   this.formatNumber = `${this.selectedCountryCode}${this.phoneSendOTP.replace(/[^+\d]/g, '')}`;

		if (this.phoneSendOTP) {
			this.formatNumber = this.phoneSendOTP.replace(/[^+\d]/g, '');
		}

        //   this.formatNumber = this.phoneSendOTP.replace(/[^+\d]/g, '');
          // Save the formatted number to the formattedPhoneNumbers array
          // this.formattedPhoneNumbers.push(this.formatNumber);

          const response = await api.post("/twilio/send-verification-token", {
            company: this.company,
            cellPhone: this.formatNumber,
          });

          const data = response.data;
		flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            data.Response
          );

        // } else {
        //   console.error("Country code or phone number is missing");
        // }
      } catch (error) {
        console.error(
          "Failed to send SMS",
          error.response ? error.response.data : error.message
        );

		flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "Failed to send SMS"
          );
      }
  },
    // Twilio Verify OTP Function - MS05152024
    async verifyOTP() {

		if (this.phoneSendOTP) {
			this.formatNumber = this.phoneSendOTP.replace(/[^+\d]/g, '');
		}

      try {
        const response = await api.post(
          "/twilio/validate-otp",
          {
            company: this.company,
            cellPhone: this.formatNumber,
            otp: this.otpInput,
          }
        );
        const data = response.data;
        this.verificationAttempted = true;
        this.isValid = data.Response === "Approved";
        if (!this.isValid) {

		flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            data.Response
          );

        } else {
		flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1
              ? this.brandData.accent_color1
              : "#FF7F50",
            this.brandData.flash_text_color
              ? this.brandData.flash_text_color
              : "#ffffff",
            "OTP Verified Successfully"
          );
        }
      } catch (error) {
        this.verificationAttempted = true;
        this.isValid = false;
        console.error(
          "Failed to verify OTP",
          error.response ? error.response.data : error.message
        );
      }
    },
    // End of 2FA Function for 2FA
  },
};
</script>

<style scoped>
.member-info-content {
  display: grid;
  /* grid-template-columns: minmax(auto, 400px) minmax(auto, 400px) 45%; */
  grid-template-columns: minmax(auto, 400px) minmax(auto, 400px) minmax(
      auto,
      auto
    );
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  column-gap: 1.5rem;

}

h4 {
  margin: 10px 0;
}

caption {
  text-align: left;
  font-weight: 600;
  margin: 10px 0;
}

.member-number-label {
  margin: 10px 0;
}

header {
  display: flex;
  justify-content: space-between;
}

label {
  font-size: 1rem;
  margin: 0;
}

span {
  font-size: 1rem;
}

input {
  width: 250px;
  padding: 0.7rem 0;
  margin: 0.5rem 0;
}

table {
  margin: 14px 0;
}

caption {
  text-align: left;
  font-weight: 600;
  margin: 10px 0;
}

select {
  display: block;
  width: 100%;
}

textarea {
  display: block;
  width: 100%;
}

.solid-border {
  border: 1px solid black;
}

.member-info {
  display: flex;
}

.member-info span:first-of-type {
  width: 420px;
}

.member-info-container {
  display: grid;
  grid-template-columns: 750px 1fr;
}

.member-info-table {
  width: 400px !important;
  display: flex;
  flex-direction: column;
}

.member-info-table tr {
  display: flex;
}

.member-info-table th,
.member-info-table td {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;
}

.member-number-label {
  display: block;
}

.btn-auth-group {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.btn-manually-auth,
.btn-manually-failed {
  width: 49%;
  height: 50px;
  background-color: #a2a7ab;
  box-shadow: 2px 4px #ccc;
  border-radius: 20px;
  border: 0;
}

.btn-save {
  margin-top: 7px;
}

.warning-code-col {
  align-self: center;
  padding-left: 2.7rem;
}

.border-black {
  border: 1px solid black;
}

.description {
  font: -webkit-control;
}

.body-container {
  align-self: unset;
  /* height: calc(100vh - 16vh); */
  height: 790px;
  min-width: 1360px;
}

.row {
  margin-bottom: 1rem;
}

.col {
  display: grid;
}

.col > div:nth-of-type(2) {
  justify-self: flex-end;
  height: 44px;
}

.col-left {
  display: inline-block;
  width: 400px;
  float: left;
}

.col-right {
  display: inline-block;
}

.select-categories {
  font-size: medium;
  padding: 5px;
}

.btn-clicked-green {
  background-color: #33cc66;
}

.btn-clicked-red {
  background-color: red;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.form {
  display: flex;
  align-items: center;
}

.button-disabled {
  /* Apply your disabled button styles here */
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity/darkness as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Adjust the z-index as needed */
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-text-large {
  font-size: 15px;
  color: white;
}

.btn {
  margin: 0 5px;
  padding: 7px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
  width: 70px;
}

.btn-cancel {
  margin-left: 10px;
}

.warning-code-table {
  width: fit-content !important;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  z-index: 2;
  /* Higher than the overlay */
  text-align: center;
}

.modal-image {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  /* Add this to show a pointer cursor on the image */
}

.membernumber-icon {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-left: 8px;
  /* Add this to show a pointer cursor on the image */
}

.close {
  position: absolute;
  top: 100px;
  /* Adjust the top position as needed */
  right: 190px;
  /* Adjust the right position as needed */
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

/* Adding Styling for 2FA */

/* Whole 2FA Container */
.flex-container {
  display: flex;
  justify-content: center; /* Center the container horizontally */
  margin-top: -140px;
}

/* 2FA Border and Label */
.two-fa-container {
  position: relative;
  margin-right: -585px;
  /* top: -23px; */
  margin-top: -430px;
  border: 2px solid #767676;
  padding: 20px;
  border-radius: 5px;
  height: 210px;
  box-shadow: 2px 4px #ccc;
}

.two-fa-container:hover {
  border: 2px solid rgba(0, 0, 0, 0.87);
}

.two-fa-label {
  position: absolute;
  top: -45px;
  left: -10px;
  background: white;
  padding: 0 10px;
  font-weight: bold;
  font-size: 110%;
}
/* End of 2FA Border and Label */

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.otp-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.verification-status {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.status-icon {
  width: 27px;
  height: 27px;
}

/* Radio Buttons Style */
.radiolist{
  display: flex;
}
.radiolist div{
  padding-right: 1rem;
  padding-bottom: .5rem;
}
.radiolist div input[type="radio"]{
  margin: 0 !important;
  margin-right: 6px !important;
  padding: 0 !important;
  width: auto;
}

</style>
