<script setup>
import DescriptionInline from "./DescriptionInline.vue"
import api from "../api/api"
import ButtonSubmit from "./ButtonSubmit.vue"
import Multiselect from "@vueform/multiselect"
import CryptoJS from "crypto-js"
import Description from "./Description.vue"
import BaseInput from "./UI/BaseInput.vue"
import { flashMessage, replaceTokens, mailFormat } from "../functions.js"
// import Swal from "sweetalert2";
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
</script>

<template>
  <div class="box">
    <form @submit.prevent="swalValidation" method="post" v-if="passedDetails == ''">
      <header>
        <h2 class>Add User</h2>
        <h2 class>AU01</h2>
      </header>

      <div class="org-label" v-if="!userData.roles.includes('16')">
        <span>
          Your organization is: <b> {{ userOrgName }} </b></span
        >
      </div>

      <div class="container">
        <div class="label-column">
          <div><label for="email">Email*</label></div>
          <div><label for="firstName">First Name*</label></div>
          <div><label for="lastName">Last Name*</label></div>
          <div><label for="password">Password*</label></div>
          <div><label for="confirmPassword">Confirm Password*</label></div>
          <div><label for="phoneNumber">Phone Number</label></div>
          <div><label for="type">Type*</label></div>
        </div>
        <div class="input-column">
          <BaseInput
            v-model="userDetails.email"
            placeholder="Email"
            type="email"
            required
            id="email"
            style="margin-bottom: 20px" />
          <BaseInput
            v-model="userDetails.first_name"
            placeholder="First Name"
            required
            id="firstName"
            style="margin-bottom: 20px" />
          <BaseInput
            v-model="userDetails.last_name"
            placeholder="Last Name"
            required
            id="lastName"
            style="margin-bottom: 20px" />
          <div style="position: relative">
            <BaseInput
              type="password"
              v-model="inputPassword"
              placeholder="Password"
              required
              id="password" />
            <label @click="showNewPassword">
              <i
                class="fa-solid fa-eye"
                id="togglePassword"
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 28%;
                  transform: translateY(-50%);
                  right: 10px;
                  z-index: 2;
                "></i>
            </label>
          </div>
          <div style="position: relative">
            <BaseInput
              type="password"
              v-model="confirmInputPassword"
              placeholder="Confirm Password"
              required
              id="confirmPassword" />
            <label @click="showConfirmPassword">
              <i
                class="fa-solid fa-eye"
                id="toggleConfirmPassword"
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 28%;
                  transform: translateY(-50%);
                  right: 10px;
                  z-index: 2;
                "></i>
            </label>
          </div>
          <vue-tel-input
            v-model="userDetails.phone_number"
            v-bind="bindProps"
            style="
              margin-bottom: 28px;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.5;
              letter-spacing: 0.00938em;
              box-shadow: 2px 4px #ccc;
              border: 2px solid black;
            "
            id="phoneNumber"></vue-tel-input>
          <div class="input-container">
            <input
              class="form-control"
              type="radio"
              id="individual"
              name="selectType"
              value="Individual"
              v-model="selectType"
              required />
            <label for="individual">Individual</label>
          </div>
        </div>
      </div>

      <div v-if="selectType === 'Individual'" class="individual-container">
        <label style="color: #ccc" for="selectRole"><DescriptionInline label="Add Role" /> </label>
        <Multiselect
          v-model="userDetails.roles"
          class="input1"
          placeholder="Add Roles"
          mode="tags"
          :searchable="true"
          :options="rolesOptions"
          :allow-empty="false"
          @remove="onRemove" />

        <div class="permissions-container">
          <!-- Permission Add User -->
          <!-- Commented permission as per requirements Security, Threshold, Whitelist, Warning Codes, Manage Brand, Company Configuration - 05272024MS -->
          <h2>Permissions</h2>
          <div class="checkbox-container">
            <label><input type="checkbox" v-model="permissions.myProfile" />My Profile</label><br />
            <label
              ><input type="checkbox" v-model="permissions.memberInfo" />Member Information</label
            ><br />
            <label><input type="checkbox" v-model="permissions.history" />History</label><br />
            <!-- <label
              ><input type="checkbox" v-model="permissions.securityInfo" />Security
              Information</label
            ><br /> -->
            <label><input type="checkbox" v-model="permissions.notes" />Notes</label><br />
            <label><input type="checkbox" v-model="permissions.manageUser" />Manage User</label
            ><br />
            <!-- <label><input type="checkbox" v-model="permissions.uploadBulkUsers">Upload Bulk Users</label><br>
            <label><input type="checkbox" v-model="permissions.viewElevatedUsers">View Elevated Users</label><br> -->
            <label
              ><input type="checkbox" v-model="permissions.adminDashboard" />Admin Dashboard</label
            ><br />
            <!-- <label
              ><input type="checkbox" v-model="permissions.thresholdSettings" />Threshold
              Settings</label
            ><br /> -->
            <!-- <label
              ><input type="checkbox" v-model="permissions.whitelistPhone" />Whitelist Phone</label
            ><br /> -->
            <label><input type="checkbox" v-model="permissions.ivrConfig" />IVR Config</label><br />
            <!-- <label><input type="checkbox" v-model="permissions.warningCodes" />Warning Codes</label
            ><br /> -->
            <!-- <label><input type="checkbox" v-model="permissions.manageBrand" />Manage Brand</label
            ><br /> -->
            <!-- <label
              ><input type="checkbox" v-model="permissions.outOfWalletQuestions" />Out of wallet
              questions</label
            ><br />-->
            <label><input type="checkbox" v-model="permissions.noteCategory" />Note Category</label
            ><br />
            <!-- <label
              ><input type="checkbox" v-model="permissions.companyConfiguration" />Company
              Configuration</label
            ><br /> -->
          </div>
        </div>
      </div>

      <br />

      <div v-if="emailNotif" class="radio-btn">
        <input
          type="radio"
          id="date-now"
          name="initiation_date"
          :value="currentDate.toISOString().slice(0, -8)"
          v-model="userDetails.initiation_date"
          @click="hideCalendar" />
        <label for="date-now">Now</label><br />

        <input
          type="radio"
          id="later-date"
          name="initiation_date"
          value="true"
          v-model="sendLater"
          @click="clearDate" />
        <label for="later-date" style="margin-right: 5px">Later</label>

        <input
          v-if="sendLater"
          class="input"
          type="datetime-local"
          id="launch"
          v-model="userDetails.initiation_date"
          @input="initiation = $event.target.value" />
      </div>
      <div class="button-container">
        <ButtonSubmit label="Add" type="submit" />
      </div>
    </form>

    <form @submit.prevent="handleUpdate" method="put" v-else>
      <!-- <h1>Edit User</h1> -->
      <header>
        <h2 class>Edit User</h2>
        <h2 class>EU01</h2>
      </header>

      <br />
      <div class="org-label" v-if="!userData.roles.includes('16')">
        <span>
          Your organization is: <b> {{ userOrgName }} </b></span
        >
      </div>

      <div class="container">
        <div class="label-column">
          <div><label for="email">Update Email*</label></div>
          <div><label for="firstName">Update First Name*</label></div>
          <div><label for="lastName">Last Name*</label></div>
          <div><label for="password">Password*</label></div>
          <div><label for="confirmPassword">Confirm Password*</label></div>
          <div><label for="phoneNumber">Phone Number*</label></div>
          <div><label for="type">Type*</label></div>
        </div>
        <div class="input-column">
          <BaseInput
            v-model="userDetails.email"
            :placeholder="passedDetails.email"
            type="email"
            required
            id="email"
            style="margin-bottom: 20px" />
          <BaseInput
            v-model="userDetails.first_name"
            :placeholder="passedDetails.first_name"
            required
            id="firstName"
            style="margin-bottom: 20px" />
          <BaseInput
            v-model="userDetails.last_name"
            :placeholder="passedDetails.last_name"
            required
            id="lastName"
            style="margin-bottom: 20px" />
          <div style="position: relative">
            <BaseInput
              type="password"
              v-model="inputPassword"
              placeholder="Password"
              id="password" />
            <label @click="showNewPassword">
              <i
                class="fa-solid fa-eye"
                id="togglePassword"
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 28%;
                  transform: translateY(-50%);
                  right: 10px;
                  z-index: 2;
                "></i>
            </label>
          </div>
          <div style="position: relative">
            <BaseInput
              type="password"
              v-model="confirmInputPassword"
              placeholder="Confirm Password"
              id="confirmPassword" />
            <label @click="showConfirmPassword">
              <i
                class="fa-solid fa-eye"
                id="toggleConfirmPassword"
                style="
                  cursor: pointer;
                  position: absolute;
                  top: 28%;
                  transform: translateY(-50%);
                  right: 10px;
                  z-index: 2;
                "></i>
            </label>
          </div>
          <vue-tel-input
            v-model="userDetails.phone_number"
            v-bind="bindProps"
            style="
              margin-bottom: 28px;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.5;
              letter-spacing: 0.00938em;
              box-shadow: 2px 4px #ccc;
              border: 2px solid black;
            "
            id="phoneNumber"></vue-tel-input>
          <div class="input-container">
            <input
              class="form-control"
              type="radio"
              id="individual"
              name="selectType"
              value="Individual"
              v-model="selectType"
              required />
            <label for="individual">Individual</label>
          </div>
        </div>
      </div>

      <div v-if="selectType === 'Individual'" class="individual-container">
        <!-- This Dropdown is Redundant to the form and will remove - 05272024MS -->
        <!-- <label style="color: #ccc" for="selectRole"><DescriptionInline label="Add Role" /> </label>
        <Multiselect
          v-model="userDetails.roles"
          class="input1"
          placeholder="Add Roles"
          mode="tags"
          :searchable="true"
          :options="rolesOptions"
          :allow-empty="false" /> -->

          <!-- Add and Relocate the exisiting dropdown in this container - 05372024MS -->
          <label style="color: #ccc" for="selectRole"><DescriptionInline label="Update Role" /> </label>
            <Multiselect
              v-model="userDetails.roles"
              class="input1"
              placeholder="Update Roles"
              mode="tags"
              :searchable="true"
              :options="rolesOptions"
              :allow-empty="false"
              required />

        <div class="permissions-container">
          <!-- Permission Edit User -->
          <!-- Commented permission as per requirements Security, Threshold, Whitelist, Warning Codes, Manage Brand, Company Configuration - 05272024MS -->
          <h2>Permissions</h2>
          <div class="checkbox-container">
            <label><input type="checkbox" v-model="permissions.myProfile" />My Profile</label><br />
            <label
              ><input type="checkbox" v-model="permissions.memberInfo" />Member Information</label
            ><br />
            <label><input type="checkbox" v-model="permissions.history" />History</label><br />
            <!-- <label
              ><input type="checkbox" v-model="permissions.securityInfo" />Security
              Information</label
            ><br /> -->
            <label><input type="checkbox" v-model="permissions.notes" />Notes</label><br />
            <label><input type="checkbox" v-model="permissions.manageUser" />Manage User</label
            ><br />
            <!-- <label><input type="checkbox" v-model="permissions.uploadBulkUsers">Upload Bulk Users</label><br>
            <label><input type="checkbox" v-model="permissions.viewElevatedUsers">View Elevated Users</label><br> -->
            <label
              ><input type="checkbox" v-model="permissions.adminDashboard" />Admin Dashboard</label
            ><br />
            <!-- <label
              ><input type="checkbox" v-model="permissions.thresholdSettings" />Threshold
              Settings</label
            ><br /> -->
            <!-- <label
              ><input type="checkbox" v-model="permissions.whitelistPhone" />Whitelist Phone</label
            ><br /> -->
            <label><input type="checkbox" v-model="permissions.ivrConfig" />IVR Config</label><br />
            <!-- <label><input type="checkbox" v-model="permissions.warningCodes" />Warning Codes</label
            ><br /> -->
            <!-- <label><input type="checkbox" v-model="permissions.manageBrand" />Manage Brand</label
            ><br /> -->
            <!-- <label
              ><input type="checkbox" v-model="permissions.outOfWalletQuestions" />Out of wallet
              questions</label
            ><br /> -->
            <label><input type="checkbox" v-model="permissions.noteCategory" />Note Category</label
            ><br />
            <!-- <label
              ><input type="checkbox" v-model="permissions.companyConfiguration" />Company
              Configuration</label
            ><br /> -->
          </div>
        </div>
      </div>

      <br />


      <br />
      <br />
      <div class="button-container">
        <ButtonSubmit
          v-if="!isDisabledButton"
          :disabled="isDisabledButton"
          label="Update"
          type="submit" />
      </div>

      <button
        v-if="isDisabledButton"
        disabled
        class="btn-disabled"
        style="display: flex; justify-content: center; align-items: center">
        <!-- <clip-loader
          :loading="true"
          :color="setColor"
          :size="setSize"
        ></clip-loader>
        <span style="margin-left: 5px">Sending email...</span> -->
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "CreateEditUserBox",
  component: [DescriptionInline, ButtonSubmit, Multiselect, Description, ClipLoader, BaseInput],
  props: ["buttonLabel", "userData", "brandData"],

  data: () => ({
    // Commented permission as per requirements Security, Threshold, Whitelist, Warning Codes, Manage Brand, Company Configuration - 05272024MS
    showUpdateForm: false,
    selectType: "",
    showIndividualContainer: false,
    permissions: {
      myProfile: false,
      memberInfo: false,
      history: false,
      // securityInfo: false,
      notes: false,
      manageUser: false,
      uploadBulkUsers: false,
      viewElevatedUsers: false,
      adminDashboard: false,
      // thresholdSettings: false,
      // whitelistPhone: false,
      ivrConfig: false,
      // warningCodes: false,
      // manageBrand: false,
      outOfWalletQuestions: false,
      noteCategory: false,
      // companyConfiguration: false,
      // Insert to automatically choose Individual in radio button of Edit User Form - 05272024MS
      selectType: 'Individual',
    },
    bindProps: {
      mode: "international",
      validCharactersOnly: true,
      defaultCountry: "US",
      dropDownOptions: {
        showSearchBox: true,
        width: "500px",
      },
      inputOptions: {
        showDialCode: true,
        placeholder: "Enter Phone Number*",
      },
    },
    currentDate: new Date(),
    emailNotif: false,
    sendLater: false,
    mode: "tags",
    closeOnSelect: false,
    rolesOptions: [],
    suborgOptions: [],
    searchable: true,
    passedDetails: [],
    passedOrgName: "",
    userOrg: null,
    userOrgName: "",
    userSubOrg: [],
    userIterations: [],
    userPrograms: [],
    roles: [],
    isDisabledButton: false,
    setColor: "#ff6a00",
    setSize: "10px",

    updateRoleInput: [],

    userDetails: {
      Company: localStorage.getItem('companyID'),
      email: "",
      first_name: "",
      auth_string: "",
      password: "",
      seed: "",
      last_name: "",
      phone_number: "",
      suppress_email_sending: 0,
      is_participant: 1,
      initiation_date: null,
      org_id: "",
      suborgs: [],
      roles: [],
      created_by: "1",
      modified_by: "1",
      indGroup: {
        ind_id: null,
        program_id: null,
        iteration_id: null,
        org_id: null,
        suborg_id: null,
        created_by: "1",
        modified_by: "1",

        home_screen: "",
        time_zone: "",
      },
    },
    inputPassword: "",
    confirmInputPassword: "",

    userAuthStringResult: "00000000000000000", // 17 items
    userAuthString: {
      // Agent: "11111000000000000",
      Agent: "11101000000000000",

      Developer: "10000000000000000",
      CoreAdmin: "10000000000000000",
      CompanyAdmin: "10001111000000000",

      // SiteAdmin: "10000000111111000",
      SiteAdmin: "11101100100100110",
    },
    tokens: {
      recipient_email: "",
      website_url: "",
      nominee_salutation: "",
      nominee_message: "",
      program_name: "",
      org_name: "",
      suborg_name: "",
      website_sender_email: "",
      website_terms_url: "",
      website_privacy_url: "",
      website_contact_email: "",
      survey_close_date: "",
      days_until_survey_close_date: "",
      survey_template_name: "",
      survey_description: "",
      user_full_name: "",
      survey_subject_first_name: "",
      survey_subject_full_name: "",
      iteration_name: "",
    },

    email_subject: "",
    email_body: "",
    email_template_id: "",
    email_ind_id: "",
    chosenSuborgList: [],
    finalsSuborgList: [],
    finalSuborgId: [],
    emailSiteManagerList: [],
  }),
  watch: {
    selectType(newType) {
      // console.log(newType)
      if (newType === "Individual") {
        if (this.passedDetails !== "" || this.passedDetails !== null) {
          this.loadUpdate()
        }
        this.getAuthString()
        this.updatePermissions(this.userAuthStringResult)
      }
    },
    'userDetails.roles': function (newValue, oldValue) {
      const removedItem = oldValue.find(item => !newValue.includes(item))
        if (removedItem) {
          this.onRemove(removedItem)
        }
    }
  },
  updated() {
    if (this.selectType === "Individual") {
      if (this.passedDetails !== "" || this.passedDetails !== null) {
        // this.loadUpdate()
      }
      this.getAuthString()
      this.updatePermissions(this.userAuthStringResult)
    }
  },
  async mounted() {
    if (this.$route.query.data) {
      this.passedDetails = JSON.parse(this.$route.query.data)
      this.userDetails.email = this.passedDetails.email
      this.userDetails.first_name = this.passedDetails.first_name
      this.userDetails.last_name = this.passedDetails.last_name
      this.userDetails.phone_number = this.passedDetails.phone_number
      this.userDetails.seed = this.passedDetails.seed
      this.userDetails.suppress_email_sending = this.passedDetails.suppress_email_sending
      this.userDetails.org_id = this.passedDetails.org_id
      this.userDetails.password = this.passedDetails.password
      this.userDetails.home_screen = this.passedDetails.home_screen
      this.userDetails.time_zone = this.passedDetails.time_zone
      // Break Here

    // if (this.$route.query.data) {
    //   this.passedDetails = JSON.parse(this.$route.query.data)
    //   this.userDetails.email = this.passedDetails.email
    //   this.userDetails.first_name = this.passedDetails.first_name
    //   this.userDetails.last_name = this.passedDetails.last_name
    //   this.userDetails.phone_number = this.passedDetails.phone_number
    //   this.userDetails.seed = this.passedDetails.seed
    //   this.userDetails.suppress_email_sending = this.passedDetails.suppress_email_sending
    //   this.userDetails.org_id = this.passedDetails.org_id
    //   this.userDetails.password = this.passedDetails.password
      // this.userDetails.home_screen = this.passedDetails.home_screen
      // this.userDetails.time_zone = this.passedDetails.time_zone
       // Insert to automatically choose Individual in radio button of Edit User Form - 05272024MS
      this.selectType = this.getSelectType();

      await api.get("individuals/" + this.passedDetails.ind_id).then((result) => {
        this.passedOrgName = result.data.org_name
      })

      if (this.passedDetails.roles) {
        this.userDetails.roles = this.passedDetails.roles.split(",").map(Number)
      }

      if (this.passedDetails.suborgs) {
        this.userDetails.suborgs = this.passedDetails.suborgs.split(",").map(Number)
      }

      await api.get("individuals/" + this.passedDetails.ind_id).then((result) => {
        this.userOrgName = result.data.org_name
      })
    }

    await api.get("roles").then((result) => {
      this.rolesOptions = result.data
      // .filter(role => role.role_id === 1 || role.role_id === 5)
        .sort((a, b) => a.role_name.localeCompare(b.role_name))
        .map((role) => {
          return { value: role.role_id, label: role.role_name }
        })
    })

    if (!this.userData.roles.includes("16")) {
      await api.get("individuals/" + this.userData.ind_id).then((result) => {
        this.userDetails.org_id = result.data.org_id
        this.userOrgName = result.data.org_name
      })
    } else {
      await api.get("individuals/" + this.userData.ind_id).then((result) => {
        this.userOrgName = result.data.org_name
      })
    }

    await api.get("organizations/").then((result) => {
      this.userOrg = result.data.sort((a, b) => a.org_name.localeCompare(b.org_name))
    })

    await api.get("get-sitemanager-emails/" + this.userData.org_id).then((result) => {
      this.emailSiteManagerList = result.data
    })
  },
  // watch: {
  //   'userDetails.roles': function (newValue, oldValue) {
  //     const removedItem = oldValue.find(item => !newValue.includes(item))
  //     if (removedItem) {
  //       this.onRemove(removedItem)
  //     }
  //   }
  // },
  methods: {
     // Add function to automatically choose Individual in radio button of Edit User Form - 05272024MS
    getSelectType() {
    return 'Individual';
  },
    showNewPassword() {
      var x = document.getElementById("password")
      if (x.type === "password") {
        x.type = "text"
      } else {
        x.type = "password"
      }
    },
    showConfirmPassword() {
      var x = document.getElementById("confirmPassword")
      if (x.type === "password") {
        x.type = "text"
      } else {
        x.type = "password"
      }
    },
    onTypeChange() {
      this.showIndividualContainer = this.selectType === "Individual"
    },
    checkNumberInArray(arr, num) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === num) {
          return true
        }
      }
      return false
    },

    randomString(length, chars) {
      var result = ""
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
      return result
    },
    encrypt(src, passphrase) {
      return CryptoJS.AES.encrypt(src, passphrase).toString()
    },

    clearDate() {
      this.userDetails.initiation_date = null
    },

    hideCalendar() {
      this.sendLater = false
    },

    swalValidation() {
      if (!mailFormat.test(this.userDetails.email)) {
        return flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Please enter a valid email address"
        )
      }

      if (this.userDetails.roles.length == 0) {
        return flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Please select a role."
        )
      }
      this.handleSubmit()
    },

    async handleSubmit() {
      if (this.inputPassword !== this.confirmInputPassword) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Password does not match!"
        )
        return
      }
      try {
        const res = await api.get(`/individuals-check-email-exist/${this.userDetails.email}`)
        if (res.data.message === "exist") {
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            res.data.payload
          )
        } else {
          this.userDetails.seed = this.randomString(
            32,
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          )
          this.getAuthString()
          this.userDetails.password = this.encrypt(this.confirmInputPassword, this.userDetails.seed)
          await api
            .post("individuals/", this.userDetails)
            .then(() => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
                this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
                "Successfully created a new User."
              )
            })
            .catch((e) => {
              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
                this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
                e.message
              )
            })

          this.chosenSuborgList.forEach((data) => {
            this.finalsSuborgList.push(data.suborg_name)
            this.finalSuborgId.push(data.suborg_id)
          })

          let suborgNames = Object.values(this.finalsSuborgList)
          let suborgId = Object.values(this.finalSuborgId)

          if (this.emailNotif !== false) {
            if (
              this.userDetails.roles.length >= 1 &&
              (this.checkNumberInArray(this.userDetails.roles, 3) ||
                this.checkNumberInArray(this.userDetails.roles, 4) ||
                this.checkNumberInArray(this.userDetails.roles, 5) ||
                this.checkNumberInArray(this.userDetails.roles, 6) ||
                this.checkNumberInArray(this.userDetails.roles, 7) ||
                this.checkNumberInArray(this.userDetails.roles, 8) ||
                this.checkNumberInArray(this.userDetails.roles, 9) ||
                this.checkNumberInArray(this.userDetails.roles, 10) ||
                this.checkNumberInArray(this.userDetails.roles, 11) ||
                this.checkNumberInArray(this.userDetails.roles, 12) ||
                this.checkNumberInArray(this.userDetails.roles, 13) ||
                this.checkNumberInArray(this.userDetails.roles, 14) ||
                this.checkNumberInArray(this.userDetails.roles, 15) ||
                this.checkNumberInArray(this.userDetails.roles, 16) ||
                this.checkNumberInArray(this.userDetails.roles, 17) ||
                this.checkNumberInArray(this.userDetails.roles, 18))
            ) {
              try {
                const res = await api.get(
                  `email-templates-make-nomination/template-type/Admin user initiation/org/${this.userDetails.org_id}/suborg/0/program/0`
                )
                if (res.status === 200) {
                  this.email_subject = res.data.payload.subject
                  this.email_body = res.data.payload.email_body
                  this.email_template_id = res.data.payload.email_template_id
                }
              } catch (e) {
                console.log(e)
              }

              try {
                const res = await api.get(`individuals/email/${this.userDetails.email}`)
                if (res.status === 200) {
                  this.email_ind_id = res.data.ind_id
                }
              } catch (e) {
                console.log(e)
              }

              try {
                const res = await api.get(`individuals/emaildetails/${this.email_ind_id}`)
                if (res.status === 200) {
                  this.tokens.recipient_email = res.data.email
                  const data = {
                    result: {
                      recipient_email: res.data.email,
                      website_url: res.data.website_url,
                      nominee_salutation: res.data.nominee_salutation,
                      nominee_message: res.data.nominee_message,
                      program_name: res.data.program_name,
                      suborg_name: suborgNames.join(),
                      website_sender_email: res.data.website_sender_email,
                      website_terms_url: res.data.website_terms_url,
                      website_privacy_url: res.data.website_privacy_url,
                      website_contact_email: res.data.website_contact_email,
                      survey_close_date: res.data.survey_close_date,
                      days_until_survey_close_date: res.data.days_until_survey_close_date,
                      survey_template_name: res.data.survey_template_name,
                      survey_description: res.data.survey_description,
                      user_full_name: res.data.first_name + " " + res.data.last_name,
                      survey_subject_first_name: res.data.survey_subject_first_name,
                      survey_subject_full_name: res.data.survey_subject_full_name,
                      iteration_name: res.data.iteration_name,
                      brand_path: res.data.brand_path,
                    },
                  }

                  this.tokens = { ...data.result }

                  let encryptedID = this.encrypt(this.email_ind_id.toString(), "seed")
                  const password_link =
                    "<a href =" +
                    this.tokens.website_url +
                    "#/set_password?ind_id=" +
                    encodeURIComponent(encryptedID) +
                    "> Click Here </a>"

                  const convertedSubject = replaceTokens(
                    this.email_subject,
                    this.tokens,
                    password_link
                  )
                  const convertedBody = replaceTokens(this.email_body, this.tokens, password_link)

                  this.rawSubject = convertedSubject
                  this.rawEmailBody = convertedBody

                  const data2 = {
                    send_from: this.tokens.website_sender_email,
                    send_to: this.tokens.recipient_email,
                    send_cc: "",
                    send_bcc: "",
                    subject: this.rawSubject,
                    body: this.rawEmailBody,
                    org_id: this.userData.org_id,
                    suborg_id: suborgId.toString() ? suborgId.toString() : 0,
                    email_template_id: this.email_template_id,
                    ind_id: this.email_ind_id,
                  }

                  try {
                    const res = await api.post("sendemail", data2)
                    if (res.status === 200) {
                      this.userDetails.email = ""
                      this.userDetails.suppress_email_sending = 0
                      this.userDetails.first_name = ""
                      this.userDetails.last_name = ""
                      this.userDetails.suborgs = []
                      this.userDetails.roles = []
                      this.userDetails.indGroup.program_id = null
                      this.userDetails.indGroup.iteration_id = null
                      this.emailNotif = false
                      this.userDetails.initiation_date = null
                      this.permissions.myProfile = false
                      this.permissions.manageUser = false
                      this.permissions.uploadBulkUsers = false
                      this.permissions.viewElevatedUsers = false
                      this.permissions.adminDashboard = false
                      this.permissions.thresholdSettings = false
                      this.permissions.whitelistPhone = false
                      this.permissions.ivrConfig = false
                      this.permissions.warningCodes = false
                      this.permissions.securityInfo = false
                      this.permissions.memberInfo = false
                      this.permissions.history = false
                      this.permissions.notes = false
                      this.permissions.outOfWalletQuestions = false
                      this.permissions.manageBrand = false
                      this.permissions.noteCategory = false
                      this.permissions.companyConfiguration = false

                      flashMessage(
                        this.$flashMessage,
                        this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
                        this.brandData.flash_text_color
                          ? this.brandData.flash_text_color
                          : "#ffffff",
                        "Email sent Successfully"
                      )
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {
                console.log(e)
              }
            }
          }
        }
      } catch (error) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          error.message
        )
      }

      this.userDetails.email = ""
      this.userDetails.suppress_email_sending = 0
      this.userDetails.first_name = ""
      this.userDetails.last_name = ""
      this.userDetails.suborgs = []
      this.userDetails.roles = []
      this.userDetails.indGroup.program_id = null
      this.userDetails.indGroup.iteration_id = null
      this.emailNotif = false
      this.userDetails.initiation_date = null
      this.inputPassword = ""
      this.confirmInputPassword = ""
      this.permissions = {
        myProfile: false,
        memberInfo: false,
        history: false,
        notes: false,
        manageUser: false,
        adminDashboard: false,
        ivrConfig: false,
        noteCategory: false
      }
    },

    async selectedSuborg(e) {
      try {
        const res = await api.post(`sub-organizations/add-user-email`, {
          suborg_id: e,
        })
        if (res.status === 200) {
          this.chosenSuborgList = res.data
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadUpdate() {
      if (this.passedDetails != "") {
        if (this.passedDetails.auth_string.toString().substring(0, 1) == "1") {
          this.permissions.myProfile = true
        }
        if (this.passedDetails.auth_string.toString().substring(1, 2) == "1") {
          this.permissions.memberInfo = true
        }
        if (this.passedDetails.auth_string.toString().substring(2, 3) == "1") {
          this.permissions.history = true
        }
        if (this.passedDetails.auth_string.toString().substring(3, 4) == "1") {
          this.permissions.securityInfo = true
        }
        if (this.passedDetails.auth_string.toString().substring(4, 5) == "1") {
          this.permissions.notes = true
        }
        if (this.passedDetails.auth_string.toString().substring(5, 6) == "1") {
          this.permissions.manageUser = true
        }
        if (this.passedDetails.auth_string.toString().substring(6, 7) == "1") {
          this.permissions.uploadBulkUsers = true
        }
        if (this.passedDetails.auth_string.toString().substring(7, 8) == "1") {
          this.permissions.viewElevatedUsers = true
        }
        if (this.passedDetails.auth_string.toString().substring(8, 9) == "1") {
          this.permissions.adminDashboard = true
        }
        if (this.passedDetails.auth_string.toString().substring(9, 10) == "1") {
          this.permissions.thresholdSettings = true
        }
        if (this.passedDetails.auth_string.toString().substring(10, 11) == "1") {
          this.permissions.whitelistPhone = true
        }
        if (this.passedDetails.auth_string.toString().substring(11, 12) == "1") {
          this.permissions.ivrConfig = true
        }
        if (this.passedDetails.auth_string.toString().substring(12, 13) == "1") {
          this.permissions.warningCodes = true
        }
        if (this.passedDetails.auth_string.toString().substring(13, 14) == "1") {
          this.permissions.manageBrand = true
        }
        if (this.passedDetails.auth_string.toString().substring(14, 15) == "1") {
          this.permissions.outOfWalletQuestions = true
        }
        if (this.passedDetails.auth_string.toString().substring(15, 16) == "1") {
          this.permissions.noteCategory = true
        }
        if (this.passedDetails.auth_string.toString().substring(16, 17) == "1") {
          this.permissions.companyConfiguration = true
        }
      }
    },
    async handleUpdate() {
      try {
        this.isDisabledButton = true
        this.getAuthString()
        this.userDetails.password = this.encrypt(this.confirmInputPassword, this.userDetails.seed)

        if (this.userDetails.roles.length == 0) {
          this.isDisabledButton = false
          return flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Roles is required."
          )
        }

        if (this.inputPassword !== this.confirmInputPassword) {
          this.isDisabledButton = false;

        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Password does not match!"
        )
        return
      }else{
        if(this.inputPassword === "" && this.confirmInputPassword === ""){
          this.userDetails.password = this.passedDetails.password
        }else{
        this.userDetails.password = this.encrypt(this.confirmInputPassword, this.userDetails.seed)
      }
    }
        const resUpdate = await api.put(
          "individuals/" + this.passedDetails.ind_id,
          this.userDetails
        )

        if (resUpdate.status == 200) {
          this.isDisabledButton = false
          return flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Updated successfully!"
          )
        }
      } catch (error) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          error.message
        )
      }
    },
    // for sending an email to all Site manager
    async sendEmailToSiteManager(emailBody) {
      // const all_emails = [
      //   { ind_id: 3, email: "jomel.gcm3@gmail.com"},
      //   { ind_id: 8, email: "jomelgithub@gmail.com"}
      // ]
      const all_emails = this.emailSiteManagerList
      const all_emails_length = all_emails.length - 1

      for (let i = 0; i < all_emails.length; i++) {
        const res2 = await api.get(`individuals/emaildetails/${all_emails[i].ind_id}`)

        const sendEmailData = {
          // send_from: "help@talentsage.com", //for testing
          // send_to: all_emails[i].email, //for testing
          send_from: res2.data.website_sender_email,
          send_to: res2.data.email,
          send_cc: "",
          send_bcc: "",
          subject: "Notification of User role change",
          body: emailBody,
          org_id: this.userData.org_id,
          suborg_id: 0,
          email_template_id: 0,
          ind_id: this.userData.ind_id,
        }

        try {
          const res = await api.post("sendemail", sendEmailData)

          if (res.data.type == "success") {
            if (i == all_emails_length) {
              this.isDisabledButton = false

              flashMessage(
                this.$flashMessage,
                this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
                this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
                res.data.message
              )
            }
          } else {
            this.isDisabledButton = false
            return flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#F47820",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              res.data.message
            )
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    onRemove(role) {
    //   // Update permissions array
    //   const permissionsArr = this.userAuthStringResult.split('')
    //   switch (role) {
    //     case 1:
    //       permissionsArr[8] = '0'
    //       break
    //     case 'User':
    //       // Do something else
    //       break
    //     case 'Guest':
    //       // Do something else
    //       break
    //     default:
    //       break
    //   }
    //   this.userAuthStringResult = permissionsArr.join('')

    if (role === 1 || role === 2) {
    // Update permissions based on removed role
      if (role === 1) {
        console.log('Removing Agent role...');
          this.permissions = {
            ...this.permissions,
            myProfile: false,
            memberInfo: false,
            history: false,
            notes: false,
            manageUser: false,
          };
      }

      if (role === 2) {
        console.log('Removing SiteAdmin role...');
        this.permissions = {
          ...this.permissions,
          myProfile: false,
          memberInfo: false,
          history: false,
          notes: false,
          manageUser: false,
          adminDashboard: false,
          ivrConfig: false,
          noteCategory: false,
          outOfWalletQuestions: false,
        };
      }
    }

    },
    updatePermissions(userAuthStringResult) {
      // console.log(userAuthStringResult)
      const permissionsArr = userAuthStringResult.split("")
      // console.log("updatePer", permissionsArr)
      for (let i = 0; i < permissionsArr.length; i++) {
        const permission = permissionsArr[i]
        switch (i) {
          case 0:
            this.permissions.myProfile = permission === "1"
            break
          case 1:
            this.permissions.memberInfo = permission === "1"
            break
          case 2:
            this.permissions.history = permission === "1"
            break
          case 3:
            this.permissions.securityInfo = permission === "1"
            break
          case 4:
            this.permissions.notes = permission === "1"
            break
          case 5:
            this.permissions.manageUser = permission === "1"
            break
          case 6:
            this.permissions.uploadBulkUsers = permission === "1"
            break
          case 7:
            this.permissions.viewElevatedUsers = permission === "1"
            break
          case 8:
            this.permissions.adminDashboard = permission === "1"
            break
          case 9:
            this.permissions.thresholdSettings = permission === "1"
            break
          case 10:
            this.permissions.whitelistPhone = permission === "1"
            break
          case 11:
            this.permissions.ivrConfig = permission === "1"
            break
          case 12:
            this.permissions.warningCodes = permission === "1"
            break
          case 13:
            this.permissions.manageBrand = permission === "1"
            break
          case 14:
            this.permissions.outOfWalletQuestions = permission === "1"
            break
          case 15:
            this.permissions.noteCategory = permission === "1"
            break
          case 16:
            this.permissions.companyConfiguration = permission === "1"
            break
          default:
            break
        }
      }
    },
    getAuthString() {
      let authString = ""
      authString += this.permissions.myProfile ? "1" : "0"
      authString += this.permissions.memberInfo ? "1" : "0"
      authString += this.permissions.history ? "1" : "0"
      authString += this.permissions.securityInfo ? "1" : "0"
      authString += this.permissions.notes ? "1" : "0"
      authString += this.permissions.manageUser ? "1" : "0"
      authString += this.permissions.uploadBulkUsers ? "1" : "0"
      authString += this.permissions.viewElevatedUsers ? "1" : "0"
      authString += this.permissions.adminDashboard ? "1" : "0"
      authString += this.permissions.thresholdSettings ? "1" : "0"
      authString += this.permissions.whitelistPhone ? "1" : "0"
      authString += this.permissions.ivrConfig ? "1" : "0"
      authString += this.permissions.warningCodes ? "1" : "0"
      authString += this.permissions.manageBrand ? "1" : "0"
      authString += this.permissions.outOfWalletQuestions ? "1" : "0"
      authString += this.permissions.noteCategory ? "1" : "0"
      authString += this.permissions.companyConfiguration ? "1" : "0"
      this.userAuthStringResult = authString

      if (
        this.userDetails.roles !== "" ||
        this.userDetails.roles !== null ||
        // this.userDetails.roles !== [] ||
        this.userDetails.roles !== undefined
      ) {
        const authStrings = {
          1: "Agent",
          2: "SiteAdmin",
          // 2: "Developer",
          // 3: "CoreAdmin",
          // 4: "CompanyAdmin",
          // 5: "SiteAdmin",
        }

        const compareAuth = this.userDetails.roles
        const searchStr = "1"
        for (const auth of Object.keys(authStrings)) {
          if (compareAuth.includes(Number(auth))) {
            const sourceStr = this.userAuthString[authStrings[auth]]
            const indexes = [...sourceStr.matchAll(new RegExp(searchStr, "gi"))].map((a) => a.index)
            for (const index of indexes) {
              const str = this.userAuthStringResult
              const replacement = "1"
              const replaced =
                str.substring(indexes[0], index) + replacement + str.substring(index + 1)
              this.userAuthStringResult = replaced
            }
          }
        }
      }

      // this.userDetails.auth_string = this.encrypt(
      //   this.userAuthStringResult,
      //   this.userDetails.seed
      // );
      this.userDetails.auth_string = this.userAuthStringResult
    },
  },
  computed: {
    mapSubOrg: function () {
      return this.userSubOrg
        .sort((a, b) => a.suborg_name.localeCompare(b.suborg_name))
        .map(function (el) {
          if (this.userDetails.org_id == el.org_id) {
            return { value: el.suborg_id, label: el.suborg_name }
          }
        }, this)
    },

    filteredSubOrg: function () {
      return this.mapSubOrg.filter(function (el) {
        return el !== undefined
      }, this)
    },

    filteredProgram: function () {
      return this.userPrograms
        .sort((a, b) => a.program_name.localeCompare(b.program_name))
        .filter(function (el) {
          if (this.userDetails.org_id != "") {
            return el.org_id == this.userDetails.org_id && el.suborg_id == this.userDetails.suborgs
          }
        }, this)
    },

    filteredIteration: function () {
      return this.userIterations
        .sort((a, b) => a.iteration_name.localeCompare(b.iteration_name))
        .filter(function (el) {
          if (this.userDetails.org_id != "") {
            return (
              el.org_id == this.userDetails.org_id &&
              el.suborg_id == this.userDetails.suborgs &&
              el.program_id == this.userDetails.indGroup.program_id
            )
          }
        }, this)
    },

    isChanged() {
      return (
        this.userDetails.email == this.passedDetails.email &&
        this.userDetails.first_name == this.passedDetails.first_name &&
        this.userDetails.last_name == this.passedDetails.last_name &&
        this.userDetails.suppress_email_sending == this.passedDetails.suppress_email_sending &&
        this.userDetails.phone_number == this.passedDetails.phone_number &&
        this.userDetails.suborgs.join(", ") == this.passedDetails.suborgs &&
        this.userDetails.roles.join(", ") == this.passedDetails.roles &&
        this.userDetails.org_id == this.passedDetails.org_id
      )
    },
  },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
header h2 {
  margin: 0;
}
header {
  display: flex;
  justify-content: space-between;
}
.radio-btn {
  margin-top: 5px;
}
.radio-btn input {
  margin-left: 20px;
}
.box {
  align-self: center;
  background: white;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 2px 10px -4px #000000;
  /* width: 60%; */
  width: 95%;
}
.btn {
  width: 100%;
  background-color: #e67829;
}

.label1 {
  top: 0px;
  left: 0px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
  font-family: Arial, Helvetica, sans-serif;
}

.input1 {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
}
.btn-disabled {
  background-color: #e2e2e2;
  color: #000000;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  font-weight: 500;
  /* margin-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px; */
  margin-top: 5px;
  padding: 8px 10px 8px 10px;
}
label,
input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.input-container > label {
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 6px;
  font-size: 1rem;
}
* {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}
.permissions-container {
  margin: 1px 0;
  max-width: 500px;
}

.checkbox-container {
  column-count: 2;
  column-gap: 5px;
}

.container {
  margin-top: 35px;
  display: flex;
  max-width: 800px;
}

.label-column {
  flex: 1;
  padding-right: 10px;
  flex-direction: column;
  max-width: 200px;
}

.label-column label {
  margin-bottom: 10px;
  height: 55px;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  justify-content: flex-end;
}

.input-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px; /* Add left margin to create space */
  max-width: 400px; /* Adjust the max-width as needed */
}
.box {
  width: 50%;
}
.button-container {
  display: flex;
  justify-content: space-around;
  max-width: 800px;
}
</style>
