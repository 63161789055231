<script setup>
import AdminLinkRouter from "./components/AdminLinkRouter.vue"
import Footer from "./components/FooterComponent.vue"
import store from "./store/store.js"
import ButtonSubmit from "../src/components/ButtonSubmit.vue"
import { useCookies } from 'vue3-cookies';
import api from "./api/api.js";
//import api from "../src/api/api.js";
</script>

<template>
  <div>
    <div v-if="displayNothing"></div>
    <div class="app-container" style="" v-if="displayApp">
      <FlashMessage time="200" position="" strategy="single" />
      <header>
        <nav class="header-container">
          <div class="menu-wrapper" v-if="checkUserLogged">
            <h1 @click="openNavbar = !openNavbar" class="menu_button">
              <i class="fa-solid fa-bars"></i>
            </h1>
          </div>
          <div class="logo-container">
            <a class="unselectable">
              <img :src="logoPath" class="brand-logo" alt="Brand Logo" />
            </a>
          </div>

          <div class="surveSubjectName">
            <div v-if="headerSubjectFullName === 'none'"></div>
            <div v-else-if="headerSubjectFullName !== ''">
              Survey for: {{ headerSubjectFullName }}
            </div>
          </div>

          <div class="user-welcome" v-if="checkUserLogged">
            <form class="form-inline">
              <div>
                Welcome! 
                <span v-if="userData.first_name == null">{{ userData.last_name }} </span>
                <span v-if="userData.last_name == null">
                  {{ userData.first_name }}
                </span>
                <span v-if="userData.first_name != null && userData.last_name != null">
                  {{ userData.first_name }} {{ userData.last_name }}
                </span>
                ({{ userData.email }})
              </div>
            </form>
            <!-- <form class="form-inline-login" v-else>
              <button class="btn">
                <i
                  class="icon-color fa-regular fa-question-circle"
                  aria-hidden="true"
                ></i>
              </button>
            </form> -->
            <div id="logout-menu" v-show="toggle" style="color: #54575b" v-on:click="clickOutside()">
              <div style="margin-bottom: 10px">Are you sure you want to Log out?</div>
              <div>
                <ButtonSubmit label="Yes! Log me out." @click="logOut" />
              </div>
            </div>
          </div>

          <div class="buttons-wrapper" v-if="checkUserLogged">
            <!-- <button class="btn" to="">
              <router-link to="">
                <div class="icon_container">
                  <i class="icon-color fa-solid fa-earth-americas"></i>
                </div>
              </router-link>
            </button>
            <button class="btn" to="">
              <router-link to="">
                <div class="icon_container">
                  <i class="icon-color fa-solid fa-bullhorn" aria-hidden="true"></i>
                </div>
              </router-link>
            </button>
            <button class="btn">
              <i class="icon-color fa-regular fa-question-circle" aria-hidden="true"></i>
            </button> -->
            <button class="btn" @click.prevent="toggle = !toggle">
              <i class="icon-color fa-solid fa-sign-out-alt" aria-hidden="true"></i>
            </button>
          </div>
        </nav>
      </header>
      <div class="flex-parent">
        <div v-show="openNavbar" class="SideNav">
          <AdminLinkRouter v-if="checkUserLogged" @navbarclick="clickOutside" />
        </div>
        <div class="flex-child" v-on:click="clickOutside()">
          <router-view
            @authenticate="setAuthenticated"
            :userData="userData"
            @passLoginUp="setLoginData"
            @passBrandUp="setBrandData"
            :brandData="brandData"
            @passHeaderSubjectFullName="setHeaderSubjectFullName"></router-view>
        </div>
      </div>
      <Footer class="footer" v-on:click="clickOutside()" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    component: { AdminLinkRouter, Footer, ButtonSubmit },
    openNavbar: false,
    // checkUserLogged: false,
    displayNothing: false,
    displayApp: true,
    toggle: false,
    userData: {},
    brandData: {
      header_text_color: "#FFFFFF",
      header_text_size: "14px",
      header_bg_color: "#fff",
      footer_text_color: "#FFFFFF",
      footer_text_size: "14px",
      footer_bg_color: "#fff",
      main_color1: "#0090D1",
      main_color2: "#0090D1",
      main_color3: "#0090D1",
      accent_color1: "#0090D1",
      button_text_color: "#FFFFFF",
      font_text_color: "#54575B",
      font: "Open Sans",
      font_title_size: "27px",
    },
    logoPath: require("@/assets/logo1.png"),
    headerSubjectFullName: "",
  }),
  updated() {
    if (this.checkUserLogged) {
      if (!this.brandData) {
        this.brandData = {
          header_text_color: "#FFFFFF",
          header_text_size: "14px",
          header_bg_color: "#fff",
          footer_text_color: "#FFFFFF",
          footer_text_size: "14px",
          footer_bg_color: "#fff",
          main_color1: "#0090D1",
          main_color2: "#0090D1",
          main_color3: "#0090D1",
          accent_color1: "#0090D1",
          button_text_color: "#FFFFFF",
          font_text_color: "#54575B",
          font: "Open Sans",
          font_title_size: "27px",
        }
        this.logoPath = require("@/assets/logo1.png")
        console.log("this.brandData else", this.brandData)
      }
    }

    const authenticate = localStorage.getItem("authenticate")
    const authenticateData = authenticate ? JSON.parse(authenticate) : false
    this.setAuthenticatedFromStorage(authenticateData)
    // console.log("Updated checkUserLogged", authenticate)
    // console.log(typeof authenticate)
    if(authenticateData==="false")
    {
      //location.reload();
      // console.log('refresh please');
      //this.$router.go('/login');
      this.$router.replace("/login")
    }
  },
  watch:
  {
    checkUserLogged(a)
    {
      // console.log("watach a",a);
      // console.log("watach a typeof",typeof a);
      if(a===false)
        {
          //location.reload();
          // console.log('refresh please');
          //this.$router.go('/login');
          this.$router.replace("/login")
        }
    }
  },
  computed: {
    checkUserLogged() {
      return store.state.isUserLogged
    }
  },
  async beforeMount() {


    const axios = require("axios")
    try {
      const resIP = await axios.get("https://ipapi.co/json/")
      if (resIP.status === 200) {
        let ip_address
        ip_address = resIP.data.ip
        // console.log("user ip address is ", ip_address)
        if (!this.brandData) {
          this.brandData = {
            header_text_color: "#FFFFFF",
            header_text_size: "14px",
            header_bg_color: "#fff",
            footer_text_color: "#FFFFFF",
            footer_text_size: "14px",
            footer_bg_color: "#fff",
            main_color1: "#0090D1",
            main_color2: "#0090D1",
            main_color3: "#0090D1",
            accent_color1: "#0090D1",
            button_text_color: "#FFFFFF",
            font_text_color: "#54575B",
            font: "Open Sans",
            font_title_size: "27px",
          }
          this.logoPath = require("@/assets/logo1.png")
          // console.log("this.brandData else", this.brandData)
        }

        if (ip_address === "50.31.112.65") {
          this.displayNothing = false
          this.displayApp = true
          return
        } else {
          if (!this.brandData) {
            this.brandData = {
              header_text_color: "#FFFFFF",
              header_text_size: "14px",
              header_bg_color: "#fff",
              footer_text_color: "#FFFFFF",
              footer_text_size: "14px",
              footer_bg_color: "#fff",
              main_color1: "#0090D1",
              main_color2: "#0090D1",
              main_color3: "#0090D1",
              accent_color1: "#0090D1",
              button_text_color: "#FFFFFF",
              font_text_color: "#54575B",
              font: "Open Sans",
              font_title_size: "27px",
            }
            this.logoPath = require("@/assets/logo1.png")
            // console.log("this.brandData else", this.brandData)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }

    const authenticate = localStorage.getItem("authenticate")
    const authenticateData = authenticate ? JSON.parse(authenticate) : false
    this.setAuthenticatedFromStorage(authenticateData)
    // console.log("beforeMount checkUserLogged", authenticate)
    // console.log(typeof authenticate)
  },
  mounted() {
    const passLoginUp = localStorage.getItem("passLoginUp")
    const passBrandUp = localStorage.getItem("passBrandUp")
    const authenticate = localStorage.getItem("authenticate")
    const passLoginUpData = passLoginUp ? JSON.parse(passLoginUp) : null
    const authenticateData = authenticate ? JSON.parse(authenticate) : false
    const passBrandUpData = passBrandUp ? JSON.parse(passBrandUp) : null

    this.setLoginDataFromStorage(passLoginUpData)
    this.setAuthenticatedFromStorage(authenticateData)
    this.setBrandDataFromStorage(passBrandUpData)

    // console.log("mounted", authenticate)
    // console.log(typeof authenticate)
    // console.log("this.brandData not yet", this.brandData)

    if (!this.brandData) {
      this.brandData = {
        header_text_color: "#FFFFFF",
        header_text_size: "14px",
        header_bg_color: "#fff",
        footer_text_color: "#FFFFFF",
        footer_text_size: "14px",
        footer_bg_color: "#fff",
        main_color1: "#0090D1",
        main_color2: "#0090D1",
        main_color3: "#0090D1",
        accent_color1: "#0090D1",
        button_text_color: "#FFFFFF",
        font_text_color: "#54575B",
        font: "Open Sans",
        font_title_size: "27px",
      }
      this.logoPath = require("@/assets/logo1.png")
      // console.log("this.brandData else", this.brandData)
    }
  },

  methods: {
    setAuthenticated(status) {
      this.checkUserLogged = status
    },
    async logOut() {
      // update session_login
      this.updateSessionLogin(this.userData.ind_id, null)

      store.state.isUserLogged = false
      const { cookies } = useCookies();
      this.$router.replace("/login")
      this.toggle = false
      this.checkUserLogged = false
      store.getters.removeAuthString
      localStorage.setItem("authenticate", this.checkUserLogged)
      localStorage.clear()
      cookies.remove("authenticate_vue-cookies")
      cookies.remove("authenticate_vue-cookies_expires")
      let myDomain = document.domain
      if (myDomain == "cutx-ec2-web.gcm3.com" && this.checkUserLogged == false) {
        //header
        this.brandData.header_text_color = "#FFFFFF"
        this.brandData.header_text_size = "14px"
        this.brandData.header_bg_color = "#fff"
        //footer
        this.brandData.footer_text_color = "#FFFFFF"
        this.brandData.footer_text_size = "14px"
        this.brandData.footer_bg_color = "#fff"
        //colors
        this.brandData.main_color1 = "#0090D1"
        this.brandData.main_color2 = "#0090D1"
        this.brandData.main_color3 = "#0090D1"
        this.brandData.accent_color1 = "#0090D1"
        this.brandData.button_text_color = "#FFFFFF"
        this.brandData.font_text_color = "#54575B"
        //font
        this.brandData.font = "Open Sans"
        this.brandData.font_title_size = "27px"
        //logo
        this.logoPath = require("@/assets/logo1.png")
      }
      try {
        const res =  api.put('/auths/lastlogout', {
          ind_id: this.userData.ind_id
        })
        if(res.status === 200){
          console.log(`${this.userData.email} successfully logged out`)
        }
      } catch (error) {
        console.log(error)
      }
    },
    sendUserData(data) {
      this.userData = data
    },
    async setLoginData(data) {
      this.userData = data
    },
    clickOutside(status) {
      this.openNavbar = status
      this.toggle = false
    },
    async setBrandData(data) {
      this.brandData = data
    },
    setHeaderSubjectFullName(data) {
      this.headerSubjectFullName = data
      console.log("laman", this.headerSubjectFullName)
      console.log("haba", this.headerSubjectFullName.length)
    },
    // Method to set the login data from session storage
    setLoginDataFromStorage(data) {
      this.userData = data
    },
    // Method to set the brand data from session storage
    setBrandDataFromStorage(data) {
      this.brandData = data
    },
    // Method to set the authentication status from session storage
    setAuthenticatedFromStorage(status) {
      this.checkUserLogged = status
    },
    // update db logged_in status
    async updateSessionLogin(ind_id, session_id) {
      await api.put(`/updateSession/${ind_id}`, {
        session_id: session_id,
        ip_address: null,
      })
    },
  },
}
</script>

<style>
* {
  font-family: "Open Sans" !important;
}

.flex-parent {
  display: flex;
  flex-direction: row;
}

.flex-child {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 88vh;
  padding: 0;
  overflow-x: auto;
}

.app-container {
  width: 100%;
  /* height: 100vh; */
  position: relative;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  background-color: #f2f2f2;
  overflow: hidden;
}

.body-container {
  background: white;
  margin: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 2px 10px -4px #000000;
  border-radius: 20px;
  align-self: center;
  /* min-height: 80vh; */
  /* min-width: 75vw; */
}

.body-form-container {
  background: white;
  margin: 50px 0 30px 0;
  box-shadow: 0px 2px 10px -4px #000000;
  border-radius: 20px;
  align-self: center;
  width: 95%;
  /* min-height: 80vh;
  margin-left: auto;
  margin-right: auto; */
}

button > i {
  pointer-events: none;
}

.buttons-wrapper {
  /* order: 4; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 10px 0 0;
  /* width: 0; */
}

.btn {
  margin: 0 3px 0 0;

  background-color: v-bind('brandData.main_color3 ? brandData.main_color3 : "#c5c5c5"');
}

.form-inline {
  padding-right: 20px;
}

.form-inline-login {
  padding-right: 20px;
}

.fa-regular {
  background: #fff;
}

.form-inline button {
  margin: 0 5px 0 5px;
}

#logout-menu {
  position: absolute;
  min-width: 250px;
  z-index: 999;
  right: 0;
  text-align: center;
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

#logout-menu,
.sn-sidenav {
  top: 55px;
}

.logo-container {
  /* order: 2; */
  padding-left: 10px;
  /* flex-grow: 2; */
  /* width: 171px; */
  height: 63px;
  margin-right: 10px;
}

.logo-container img {
  height: 100%;
  object-fit: cover;
  padding: 0;
  margin: 0;
}
.surveSubjectName {
  /* order: 3; */
  flex-grow: 2;
}

/* header css dynamic */
.header-container {
  color: v-bind('brandData.header_text_color ? brandData.header_text_color : "#0E5071"');
  font-size: v-bind('brandData.header_text_size ? brandData.header_text_size+"px" : "14px"');
  background-color: v-bind('brandData.header_bg_color ? brandData.header_bg_color: "#FFFFFF"');
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
  height: 7vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  width: 100%;
}

/* footer css dynamic */
.footer-text-color {
  color: v-bind('brandData.footer_text_color ? brandData.footer_text_color : "#0E5071"');
  font-size: v-bind('brandData.footer_text_size ? brandData.footer_text_size+"px" : "14px"');
  background-color: v-bind('brandData.footer_bg_color ? brandData.footer_bg_color: "#FFFFFF"');
}

.footer-text-color a {
  color: v-bind('brandData.footer_text_color ? brandData.footer_text_color : "#0E5071"');
}

.icon_container {
  color: rgb(87, 86, 86);
}

.icon-color {
  color: v-bind('brandData.accent_color1 ? brandData.accent_color1 : "#F47820"');
}

/* only for icon on the sidebar  */
.icon-color-sidebar {
  color: v-bind('brandData.main_color1 ? brandData.main_color1 : "#0E5071"');
}

.menu_button {
  padding-left: 10px;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
* {
  font-family: v-bind('brandData.font ? brandData.font : "Open Sans"');
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
option,
select,
button,
table,
th,
tr,
td,
input,
textarea {
  color: v-bind('brandData.font_text_color ? brandData.font_text_color : "#fff"');
}

/* brand text-color  */
.text-main-color1 {
  color: v-bind('brandData.main_color1 ? brandData.main_color1 : "#fff"');
}
.text-main-color2 {
  color: v-bind('brandData.main_color2 ? brandData.main_color2 : "#fff"');
}
.text-main-color3 {
  color: v-bind('brandData.main_color3 ? brandData.main_color3 : "#fff"');
}
.text-accent-color1 {
  color: v-bind('brandData.accent_color1 ? brandData.accent_color1 : "#fff"');
}

/* brand background color  */
.bg-main-color1 {
  background-color: v-bind("brandData.main_color1");
}
.bg-main-color2 {
  background-color: v-bind("brandData.main_color2");
}
.bg-main-color3 {
  background-color: v-bind("brandData.main_color3");
}
.bg-accent-color1 {
  background-color: v-bind("brandData.accent_color1");
}

/* brand font text size  */
.font-text-size {
  font-size: v-bind('brandData.font_text_size ? brandData.font_text_size+"px" : "14px"');
}

/* brand font title size  */
.font-title-size {
  font-size: v-bind('brandData.font_title_size ? brandData.font_title_size+"px" : "27px"');
}

/* CSS for Header Component  */
.header {
  font-weight: bold;
  font-size: v-bind('brandData.font_title_size ? brandData.font_title_size+"px" : "27px"');
  color: v-bind('brandData.font_title_color ? brandData.font_title_color : "#fff"');
}

/* button submit component */
.btn-submit {
  appearance: none;
  background-color: v-bind('brandData.main_color2 ? brandData.main_color2 : "#0090D1"');
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 20px;
  box-sizing: border-box;
  color: v-bind('brandData.button_text_color ? brandData.button_text_color : "#fff"');
  cursor: pointer;
  display: inline-block;
  font-size: v-bind('brandData.font_text_size ? brandData.font_text_size+"px" : "14px"');
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  box-shadow: none;
}
.btn-submit:hover {
  /* background-color: v-bind('brandData.main_color2'); */
  text-decoration: none;
  background-color: v-bind('brandData.main_color2 ? brandData.main_color2 : "#0090D1"');
  opacity: 0.8;
  transition-duration: 0.1s;
}
.btn-submit:disabled {
  /*  background-color: #9ccde4;*/
  background-color: #6c757d;
  border-color: rgba(27, 31, 35, 0.034);
  color: #fff;
  cursor: default;
  pointer-events: none;
  opacity: 0.65;
}
.btn-submit:active {
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  background-color: v-bind('brandData.main_color2 ? brandData.main_color2 : "#0090D1"');
  opacity: 0.6;
  transition: none 0s;
}
.btn-submit:focus {
  outline: 1px transparent;
}
.btn-submit:before {
  display: none;
}
.btn-submit:-webkit-details-marker {
  display: none;
}

/* button cancel component  */
.btn-cancel {
  appearance: none;
  background-color: #e2e2e2;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 20px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: v-bind('brandData.font_text_size ? brandData.font_text_size+"px" : "14px"');
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  margin-left: 20px;
}
.btn-cancel:hover {
  background-color: #eeeeee;
  text-decoration: none;
  transition-duration: 0.1s;
}
.btn-cancel:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
  pointer-events: none;
}
.btn-cancel:active {
  background-color: #e2e2e2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}
.btn-cancel:focus {
  outline: 1px transparent;
}
.btn-cancel:before {
  display: none;
}
.btn-cancel:-webkit-details-marker {
  display: none;
}

.btn-delete {
  appearance: none;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 20px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: v-bind('brandData.font_text_size ? brandData.font_text_size+"px" : "14px"');
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  margin-left: 20px;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  border: 1px solid rgba(27, 31, 35, 0.15);
  background-color: #e2e2e2;
  color: #000000;
  cursor: pointer;
  z-index: 1052;
  box-shadow: 2px 4px #ccc;
}

.close:hover {
  opacity: 0.75;
}

/* table  */
#table {
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f2f2f2;
}
#table tr:hover {
  background-color: #ddd;
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0e5071;
  color: white;
}

/* form box  */
.formBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 10px 10px;
  margin: 1em 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-top: 4px solid v-bind('brandData.main_color1 ? brandData.main_color1 : "#0E5071"');
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* input type  */
.input {
  outline: 0;
  border-width: 0 0 1px;
  border-color: grey;
  padding: 2px 5px;
  margin: 10px 0px;
  background: none;
}

.form-control-select {
  padding: 8px 5px;
  height: 43.2px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #767676;
}

.form-control-textarea {
  padding: 8px 5px;
  height: 100px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  border-radius: 4px;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #767676;
}

dialog {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 20px;
  padding: 0;
  position: fixed;
  left: 0;
  top: 50%;
  background-color: #fff;
  z-index: 100;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #767676;
}

dialog > .dialog-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1001;
}

dialog > .dialog-header {
  margin: 0;
  margin-bottom: 16px;
  font-size: 24px;
}

dialog > .dialog-body {
  background-color: #fff;
  padding: 2rem;
  border-radius: 20px;
}

dialog .button-group {
  text-align: center;
}

dialog label {
  font-size: 16px !important;
}

label {
  font-size: v-bind('brandData.font_text_size ? brandData.font_text_size+"px" : "14px"');
  font-weight: 500;
  display: inline-block;
  color: v-bind('brandData.font_text_color ? brandData.font_text_color : "#54575B"');
  margin-bottom: 0.5rem;
}
.popup-message {
  border-radius: 20px !important;
  box-shadow: 2px 4px #ccc !important;
  border: 2px solid #767676;
  right: 10px;
  top: 10px;
  animation: 1.5s ease slide-in forwards, 100ms ease-out 2.7s fade-out;
}

._vue-flash-msg-body {
  border-radius: 20px !important;
  box-shadow: 2px 4px #ccc !important;
  border: 2px solid #767676;
  right: 10px;
  top: 10px;
  animation: 1.5s ease slide-in forwards, 100ms ease-out 2.7s fade-out;
}
/* random css design  */
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.text-center {
  text-align: center;
}

/* margins  */
.ml-1 {
  margin-left: 1rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mt-1 {
  margin-top: 1rem;
}

@media only screen and (min-width: 280px) and (max-width: 900px) {
  .app-container {
    width: 100%;
    font-size: 12px;
    position: relative;
    display: flex;
    align-content: stretch;
    flex-direction: column;
    background-color: #f2f2f2;
    overflow-x: hidden;
  }

  .body-form-container {
    font-size: 12px;
    width: 98%;
    background: white;
    margin: 40px 0 30px 0;
    box-shadow: 0px 2px 10px -4px #000000;
    border-radius: 20px;
    align-self: center;
  }

  .header-container {
    font-size: 12px;
    width: 100%;
    height: 100%;
    padding: 0 0 10px 0;
  }

  .logo-container {
    width: 50%;
  }

  .logo-container img {
    height: 100%;
    width: 30vw;
    object-fit: contain;
    padding: 0;
    margin: 0;
  }

  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2px 0 0 0;
    margin: 0;
    width: 100%;
  }

  .user-welcome {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 0 0;
    border-top: 1px dashed #c3bcbc;
    padding: 5px 0 0 0;
    font-size: 10px;
  }

  .flex-child {
    padding: 20px 0 0 0;
    min-height: 80vh;
  }

  .form-control {
    font-size: 12px !important;
  }

  .form-inline {
    padding: 0;
  }

  .form-inline-login {
    padding: 10px 0 0 0;
  }

  .box-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .parent {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
  }

  #logout-menu {
    margin: 0;
    width: 100%;
    padding: 10px;
    right: unset;
  }

  .flex-parent {
    margin: 0 0 20px 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  40% {
    transform: translateX(-10%);
  }
  80% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-10px);
  }
}

/* @media only screen and (min-width: 280px) {
  .footer-text-color {
    display: none;
  }
} */

/* @media screen and (max-width: 100px) {
  .app-container {
    width: 100%;
    font-size: 10px;
    height: 80vh;
    margin: 30px;
  }

  .body-form-container {
    font-size: 10px;
    width: 100%;
    height: 80vh;
    margin: 30px;
  }

  .header-container {
    font-size: 10px;
    width: 50%;
  }

  .logo-container {
    width: 50%;
  }

  .buttons-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 10px 0 0;
    width: 100%;
  }

  .user-welcome{
    width: 100%;
    display: flex;
    justify-content: center;
  }

} */

/* end random css design  */
</style>
