<script setup>
import Header from "./Header.vue"
import api from "../api/api"
import BaseButton from "./UI/BaseButton.vue"
import BaseInput from "./UI/BaseInput.vue"
import ButtonSubmit from "./ButtonSubmit.vue"
import ButtonCancel from "./ButtonCancel.vue"
import { flashMessage } from "@/functions"
</script>

<template>
  <div class="body-container">
    <header>
      <h2>Threshold Settings</h2>
      <h2>T01</h2>
    </header>

    <div class="settings-body">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Caller ID</th>
            <th>Caller Member</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Threshold Active?</td>
            <td>
              <fieldset id="taCI">
                <div>
                  <input
                    type="radio"
                    name="taCI"
                    id="taCI-yes"
                    value="1"
                    v-model="dataSettingsCI.thresholdActive" />
                  <label for="taCI-yes">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="taCI"
                    id="taCI-no"
                    value="0"
                    v-model="dataSettingsCI.thresholdActive" />
                  <label for="taCI-no">No</label>
                </div>
              </fieldset>
            </td>
            <td>
              <fieldset id="taCM">
                <div>
                  <input
                    type="radio"
                    name="taCM"
                    id="taCM-yes"
                    value="1"
                    v-model="dataSettingsCM.thresholdActive" />
                  <label for="taCM-yes">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="taCM"
                    id="taCM-no"
                    value="0"
                    v-model="dataSettingsCM.thresholdActive" />
                  <label for="taCM-no">No</label>
                  <!-- <label for="taCM-no" style="position: relative; top: 1px">No</label> -->
                </div>
              </fieldset>
            </td>
          </tr>
          <tr>
            <td>Number of hours to look back when determining if a threshold has been exceeded:</td>
            <td>
              <BaseInput
                type="number"
                name="numberOfHoursCI"
                id="numberOfHoursCI"
                v-model="dataSettingsCI.numberOfHours" />
            </td>
            <td>
              <BaseInput
                type="number"
                name="numberOfHoursCM"
                id="numberOfHoursCM"
                v-model="dataSettingsCM.numberOfHours" />
            </td>
          </tr>
          <tr>
            <td>Send or display alert when number of calls/member inquiries meets or exceeds:</td>
            <td>
              <BaseInput
                type="number"
                name="maxNumberOfCallsCI"
                id="maxNumberOfCallsCI"
                v-model="dataSettingsCI.numberOfCalls" />
            </td>
            <td>
              <BaseInput
                type="number"
                name="maxNumberOfCallsCM"
                id="maxNumberOfCallsCM"
                v-model="dataSettingsCM.numberOfCalls" />
            </td>
          </tr>
          <tr>
            <td>Send email when threshold meets or exceeds?</td>
            <td>
              <fieldset id="sendEmailCI">
                <div>
                  <input
                    type="radio"
                    name="sendEmailCI"
                    id="sendEmailCI-yes"
                    value="1"
                    v-model="dataSettingsCI.thresholdEmailExceeds" />
                  <label for="sendEmailCI-yes">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="sendEmailCI"
                    id="sendEmailCI-no"
                    value="0"
                    v-model="dataSettingsCI.thresholdEmailExceeds" />
                  <label for="sendEmailCI-no">No</label>
                </div>
              </fieldset>
            </td>
            <td>
              <fieldset id="sendEmailCM">
                <div>
                  <input
                    type="radio"
                    name="sendEmailCM"
                    id="sendEmailCM-yes"
                    value="1"
                    v-model="dataSettingsCM.thresholdEmailExceeds" />
                  <label for="sendEmailCM-yes">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="sendEmailCM"
                    id="sendEmailCM-no"
                    value="0"
                    v-model="dataSettingsCM.thresholdEmailExceeds" />
                  <label for="sendEmailCM-no">No</label>
                </div>
              </fieldset>
            </td>
          </tr>
          <tr>
            <td>Threshold alert email address:</td>
            <td>
              <BaseInput
                type="email"
                name="alertEmailAddressCI"
                id="alertEmailAddressCI"
                v-model="dataSettingsCI.thresholdEmailAddressAlert" />
            </td>
            <td>
              <BaseInput
                type="email"
                name="alertEmailAddressCM"
                id="alertEmailAddressCM"
                v-model="dataSettingsCM.thresholdEmailAddressAlert" />
            </td>
          </tr>
          <tr>
            <td>Warning message to be displayed or emailed when threshold is met or exceeded:</td>
            <td>
              <textarea
                rows="5"
                name="warningMessageCI"
                id="warningMessageCI"
                v-model="warningMessageCI"
                readonly
                placeholder="Global Warning: This caller ID has called x or more times in the last y hrs.">
              </textarea>
            </td>
            <td>
              <textarea
                rows="5"
                name="warningMessageCM"
                id="warningMessageCM"
                v-model="warningMessageCM"
                placeholder="Global Warning: This member number has been used x or more times in the last y hrs."
                readonly>
              </textarea>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <ButtonSubmit type="button" label="Update" @click="updateThresholdSettings" />
              <ButtonCancel type="button" label="Cancel" @click="cancelThresholdSettings" />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThresholdSettingsComponent",
  component: [Header, BaseButton, BaseInput],
  props: ["brandData"],
  data: () => {
    return {
      companyID: localStorage.getItem('companyID'),
      dataSettingsCI: {
        thresholdActive: "",
        numberOfHours: 0,
        numberOfCalls: 0,
        thresholdEmailExceeds: "0",
        thresholdEmailAddressAlert: "",
        warningMessage: "",
      },
      dataSettingsCM: {
        thresholdActive: "",
        numberOfHours: 0,
        numberOfCalls: 0,
        thresholdEmailExceeds: "0",
        thresholdEmailAddressAlert: "",
        warningMessage: "",
      },
      warningMessageCI: "",
      warningMessageCM: "",
    }
  },
  mounted() {
    this.getThresholdSettings()
  },
  methods: {
    async getThresholdSettings() {
      try {
        const { data } = await api.get(`/update_threshold/${this.companyID}`)

        // Caller ID
        this.dataSettingsCI.numberOfCalls = data[0].MaxNumCallsCaller
        this.dataSettingsCI.numberOfHours = data[0].NumHoursCaller
        this.dataSettingsCI.thresholdEmailAddressAlert = data[0].EmailAddressCaller
        this.dataSettingsCI.thresholdActive = data[0].ThresholdActiveCaller
        this.dataSettingsCI.thresholdEmailExceeds = data[0].SendEmailCaller

        // Caller Member
        this.dataSettingsCM.numberOfCalls = data[0].MaxNumCallsMember
        this.dataSettingsCM.numberOfHours = data[0].NumHoursMember
        this.dataSettingsCM.thresholdEmailAddressAlert = data[0].EmailAddressMember
        this.dataSettingsCM.thresholdActive = data[0].ThresholdActiveMember
        this.dataSettingsCM.thresholdEmailExceeds = data[0].SendEmailMember

        this.warningMessageCI = `Global Warning: This caller ID has called ${data[0].MaxNumCallsCaller} or more times in the last ${data[0].NumHoursCaller} hours.`
        this.warningMessageCM = `Global Warning: This member number has been used ${data[0].MaxNumCallsMember} or more times in the last ${data[0].NumHoursMember} hours.`

      } catch (error) {
        console.log(error)
      }
    },
    async updateThresholdSettings() {
      let dataSettings = {
        ci: this.dataSettingsCI,
        cm: this.dataSettingsCM,
        company: this.companyID,
      }

      try {
        const { data } = await api.put("update_threshold", dataSettings)

        if (data.affectedRows == 1) {
          this.getThresholdSettings()
          flashMessage(
            this.$flashMessage,
            this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
            this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
            "Threshold settings updated successfully."
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
    cancelThresholdSettings() {
      this.getThresholdSettings()
      flashMessage(
        this.$flashMessage,
        this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
        this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
        "Threshold settings canceled successfully."
      )
    },
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
}
.form-group {
  width: 230px;
}

.body-container {
  align-self: unset;
}
.settings-body {
  display: flex;
  /* justify-content: flex-start; */
}
table {
  border-spacing: 5px 10px;
  table-layout: fixed;
}
th {
  text-align: left;
  font-weight: bold;
}

tr {
  margin-bottom: 10px;
}
textarea {
  width: 97%;
  vertical-align: text-top;
}

tr td {
  vertical-align: top;
}
tr td:nth-child(1) {
  text-align: right;
  display: block;
  font-size: 16px;
  width: 376px;
}

tr td:nth-child(2) {
  vertical-align: top;
}
/*button {
    background-color: steelblue;
    color: white;
    border: 1px solid transparent;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 4px;
}*/

input[type="number"],
input[type="email"] {
  margin-left: 50px;
  /* border-radius: 2px;
    padding: 3px;
    border: 1px solid gray;*/
  outline: 0;
  /*    border-width: 0 0 1px;*/
  /*    border-color: grey;*/
  padding: 2px 5px;
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

fieldset > div {
  display: inline-block;
  margin-right: 6px;
  position: relative;
}

input[type="radio"] {
  position: relative;
  top: 2px;
}
</style>
