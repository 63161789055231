<script setup>
import api from "../api/api.js"
import store from "../store/store.js"
import CryptoJS from "crypto-js"
import ButtonSubmit from "./ButtonSubmit.vue"
import { flashMessage } from "../functions.js"
import Bowser from "bowser"
// import { useCookies } from 'vue3-cookies';
import Swal from "sweetalert2"
</script>

<template>
  <div v-if="isLoggedIn">
    <div>
    </div>
  </div>
  <div class="login-container" v-else>
    <div class="login-form">
      <div>
        <form @submit.prevent="handleLogin()" method="post">
          <h3>
            Welcome!
            <!-- <br>Sign in here to grow your leadership skills. -->
          </h3>
          <div class="form-group">
            <input type="text" name="username" v-model="username" class="form-control" placeholder="Email Address "
              required="required" id="emailInput" />
          </div>
          <div class="form-group">
            <input type="password" name="password" v-model="password" class="form-control"
              placeholder="Password  (8+ characters)" required="required" id="passwordInput" />
            <span @click="showPassword()">
              <i class="fa fa-eye" aria-hidden="true" id="eye"></i>
            </span>
          </div>
          <div class="form-group button-div">
            <ButtonSubmit label="Sign In" style="border: 1px; box-shadow: 2px 4px #ccc" />
          </div>
        </form>
        <div class="forgot-password">
          <p><router-link to="/forgot_password_screen">Forgot password?</router-link></p>
        </div>
        <div class="btm-msg">
          <h3>
            Click "Sign In" to agree with Member App's <a href="#">Terms of Service <br /></a>and
            acknowledge that Member App's <br /><a href="http://localhost:8080/#/privacy_page">Privacy Policy</a>
            applies to you.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  component: [ButtonSubmit],
  name: "LoginComponent",
  data: () => ({
    isLoggedIn: false,
    logOutButton: true,
    username: "",
    password: "",
    Company: "",
    loginResults: [],
    routeAuthResults: [],
    Roles: {},
    currentRole: [],
    platform: "",
    browser: "",
    deviceCore: "",
    operatingSystem: "",
    ind_id: null,
    ipAddress: "",
  }),
  methods: {
    async handleLogin() {
  try {
    const response = await api.post("auths", {
      email: this.username.toLowerCase().trim(),
      password: this.password,
      Company: localStorage.getItem('companyID')
    });

    this.loginResults = response.data;

    if (this.loginResults.length <= 0) {
      flashMessage(this.$flashMessage, "#0a58ca", "#ffffff", "Wrong username or password!");
      this.userLog(0, 0);
      return null;
    } else if (this.loginResults[0].roles == "" || this.loginResults[0].roles == null) {
      flashMessage(this.$flashMessage, "#0a58ca", "#ffffff", "Invalid user");
      this.userLog(0, 0);
      return null;
    }

    this.ind_id = this.loginResults[0].ind_id;

    // check if the user is already logged in
    const ip_address = this.loginResults[0].ip_address || "";
    if (this.loginResults[0].isUserLogged == 1 && this.loginResults[0].session_login != null) {
      Swal.fire({
        title: `Duplicate session detected at IP ${ip_address}`,
        text: `Do you want to terminate your previous session?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          this.redirectToHome();
        }
      });
    } else {
      this.redirectToHome();
    }

  } catch (error) {
    console.error('Error in authentication request:', error);
  }
},

    async redirectToHome() {
      this.Roles = this.loginResults[0].roles

      // update session_login
      const session = this.getDateTime()
      localStorage.setItem("session", "session-" + session)
      await api.put(`/updateSession/${this.ind_id}`, {
        session_id: "session-" + session,
        ip_address: this.ipAddress,
      })

      try {
        const response = await api.put("/auths/lastlogin", {
          email: this.username.toLowerCase().trim(),
        })

        if (response.status === 200) {
          api
            .get("roles")
            .then((result) => {
              var rolesData = result.data
              var newValue = this.Roles.split(", ")
              var newRoleName = newValue.map((r) => rolesData.find((f) => f.role_id == r).role_name)
              this.currentRole = newRoleName
              this.$emit("passLoginUp", this.loginResults[0])
              this.$emit("passBrandUp", this.loginResults[0].brandData)

              // Store passLoginUp and passBrandUp in session storage
              localStorage.setItem("passLoginUp", JSON.stringify(this.loginResults[0]))
              localStorage.setItem("passBrandUp", JSON.stringify(this.loginResults[0].brandData))
              store.commit("addAuthString", this.loginResults[0].auth_string)
              this.isLoggedIn = true
              localStorage.setItem("authenticate", JSON.stringify(this.isLoggedIn))
              this.$emit("authenticate", this.isLoggedIn)

              // let expirationDate = new Date();
              // expirationDate.setHours(expirationDate.getHours() + 24); // Set the expiration time to 30 minutes from now
              // cookies.set('authenticate_vue-cookies', JSON.stringify(this.isLoggedIn), { expires: expirationDate });
              // cookies.set('authenticate_vue-cookies_expires', expirationDate.toUTCString());

              let expirationDate = new Date()
              expirationDate.setHours(expirationDate.getHours() + 24) // Set the expiration time to 24 hours from now
              // expirationDate.setSeconds(expirationDate.getSeconds() + 10); // Set the expiration time to 24 hours from now

              store.state.isUserLogged = true

              // Convert expirationDate to local time in "Africa/Cairo" timezone
              let localExpirationDate = expirationDate.toLocaleString("en-US", {
                timeZone: "Asia/Hong_Kong",
              })

              // cookies.set('authenticate_vue-cookies', JSON.stringify(this.isLoggedIn), { expires: expirationDate });
              // cookies.set('authenticate_vue-cookies_expires', localExpirationDate);
              localStorage.setItem("authenticate_vue-cookies_expires", localExpirationDate)

              this.userLog(1, this.loginResults[0].ind_id)
              this.$router.push(this.loginResults[0].home_screen + "?AuthToken=" + localStorage.getItem("authToken"))

              this.$flashMessage.show({
                html: `<div style="background-color: ${this.loginResults[0].brandData.accent_color1
                  ? this.loginResults[0].brandData.accent_color1
                  : "#F47820"
                  }; width:100%;">
                <p style="margin-bottom:0; margin-left:1rem; color: #fff;">Welcome ${this.loginResults[0].first_name
                  } !</p>
                <p style="margin-top:0; margin-left:1rem; color: #fff;">Role(s): ${this.currentRole.join(
                    ", "
                  )}</p>
              </div>`,
                clickable: true,
              })
            })
            .catch((e) => {
              flashMessage(this.$flashMessage, "#F47820", "#ffffff", e.message)
              return null
            })
        }
      } catch (error) {
        // Handle the exception/error here
        console.error("An error occurred:", error)
      }
    },
    showPassword() {
      var x = document.getElementById("passwordInput")
      if (x.type === "password") {
        x.type = "text"
      } else {
        x.type = "password"
      }
    },
    decrypt(src, passphrase) {
      const bytes = CryptoJS.AES.decrypt(src, passphrase)
      return bytes.toString(CryptoJS.enc.Utf8)
    },
    async userLog(loggedIn, indId) {
      try {
        const res = await api.post("insertUserLog", {
          email: this.username,
          ind_id: indId,
          machine_id: "",
          ip_address: "",
          is_logged_in: loggedIn,
          platform: this.platform,
          operating_system: this.operatingSystem,
          browser: this.browser,
          logical_processors: this.deviceCore,
        })
        if (res) {
         // console.log(res)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getDateTime() {
      // Create a new Date object
      const date = new Date()

      // Get the individual components
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0") // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, "0")
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const seconds = String(date.getSeconds()).padStart(2, "0")

      // Concatenate the components
      const output = year + month + day + hours + minutes + seconds
      return output // Output: 202307111005
    },
    getIPAddress() {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          const ipAddress = data.ip
          this.ipAddress = ipAddress
        })
        .catch((error) => {
          console.error("Error:", error)
        })
    },
    async getIVRConfigOptionsCompanyByUrl() {
      const currentURL = window.location.href;
      const baseURL = currentURL.split('#')[0].replace(/\/$/, ''); // Remove trailing slash

      await api
        .post("getIVRConfigOptionsCompanyByUrl", {
          "url": baseURL
        })
        .then((res) => {
          const companyID = res.data.length != 0 ? res.data[0].Company : 0
          localStorage.setItem('companyID', companyID)
        })
    }
  },
  async mounted() {
    this.getIVRConfigOptionsCompanyByUrl() //get company_id
    this.getIPAddress()
    const browser = Bowser.getParser(window.navigator.userAgent)
    this.platform = browser.getPlatformType()
    this.browser = `${browser.getBrowserName()} V.${browser.getBrowserVersion()}`
    this.operatingSystem = `${browser.getOSName()} ${browser.getOSVersion()}`

    if (navigator.hardwareConcurrency) {
      this.deviceCore = navigator.hardwareConcurrency + " cores"
    } else {
      this.deviceCore = "N/A"
    }
  },
}
</script>

<style scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88vh;
  margin: 0;
  padding: 0;
}

.login-form {
  background: white;
  border-radius: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  box-shadow: 0px 2px 10px -4px #000000;
  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

.btn {
  width: 30%;
  font-size: 100%;
  line-height: 1.15;
  font-weight: 500;
  margin: 6px 0;
  cursor: pointer;
  letter-spacing: 0.03em;
  padding: 10px 26px;
  box-shadow: 0 0 2px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 20%);
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"] {
  transform: scale(2);
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

.form-control {
  height: 56px;
  font-size: 1rem;
}

.form-group input {
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  padding: 16.5px 14px;
  box-shadow: 2px 4px #ccc;
  border: 2px solid #0e5071;
}

.form-group {
  position: relative;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: 10px;
}

label {
  font-size: 14px;
  padding-left: 16px;
}

.btm-msg {
  text-align: center;
  margin-top: 50px;
  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

span {
  position: absolute;
  top: 37%;
  left: 93%;
  transform: translate(0, -50%);
  cursor: pointer;
  right: 15px;
  color: #0090d1;
  font-size: 25px;
}

span:hover {
  color: #0090d1;
}

span:active {
  color: #0090d1;
}

.forgot-password {
  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

.button-div {
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {
  .fa-eye {
    margin-left: -15px;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
}
</style>
