<script setup>
import api from "../api/api"
import ButtonSubmit from "./ButtonSubmit.vue"
import Swal from "sweetalert2"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import BaseInput from "./UI/BaseInput.vue"
import BaseButton from "./UI/BaseButton.vue"
</script>

<template>
  <div class="brandSection">
    <div class="whitelist-header">
      <h2 class>Whitelist Phone</h2>
      <h2 class>W01</h2>
    </div>

    <div v-if="isLoading">
      <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
    </div>

    <div v-else>
      <div class="tableFixHead">
        <table id="table">
          <thead>
            <tr @click="disableActiveClass">
              <th style="text-align: center">Num</th>
              <th @click="sort('PhoneNumberToWhitelist')">
                Phone Number &nbsp;<span
                  ><i :class="[sortDesc ? 'arrow-down' : 'arrow-up']"></i
                ></span>
              </th>
              <th>Action</th>
            </tr>
            <tr>
              <th @click="disableActiveClass" class="td1"></th>

              <th class="td1">
                <div v-if="active_el == null">
                  <!-- <input
                    type="text"
                    v-model="phoneNumber"
                    class="phone-number-field"
                    placeholder="Enter Phone Number" /> -->
                  <BaseInput
                    type="text"
                    v-model="phoneNumber"
                    class="phone-number-field"
                    placeholder="Enter Phone Number" />
                </div>

                <div v-else>
                  <!-- <input
                    type="text"
                    v-model="updatePhoneNumber"
                    class="phone-number-field"
                    placeholder="Update Phone Number" /> -->
                  <BaseInput
                    type="text"
                    v-model="updatePhoneNumber"
                    class="phone-number-field"
                    placeholder="Update Phone Number" />
                </div>
              </th>

              <th class="td1">
                <div v-if="active_el == null">
                  <BaseButton @click="insertWhiteListPhone" class="btn-submit">
                    <i class="fa-solid fa-check"></i> Save
                  </BaseButton>
                  <!-- <button @click="insertWhiteListPhone" class="btn btn-submit" style="width: 93px">
                    <i class="fa-solid fa-check"></i> Save
                  </button> -->
                </div>

                <div v-else>
                  <BaseButton @click="updateWhiteListPhone" class="btn-submit">
                    <i class="fa-solid fa-check"></i> Update
                  </BaseButton>
                  <span style="margin: 0 6px"></span>
                  <BaseButton @click="disableActiveClass" class="btn-cancel">Cancel</BaseButton>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(phone, index) in filteredPhoneNumbers"
              :key="index"
              @click="selectRow(phone.IndexNum, phone.PhoneNumberToWhitelist)"
              style="cursor: pointer"
              :class="{ active: active_el == phone.IndexNum }">
              <td style="text-align: right">{{ index + 1 }}</td>
              <td>{{ phone.PhoneNumberToWhitelist }}</td>
              <td>
                <BaseButton
                  @click="deleteWhiteListPhone(phone.IndexNum, phone.PhoneNumberToWhitelist)"
                  class="btn-danger">
                  <i class="fa-solid fa-trash"></i> Delete
                </BaseButton>
                <span style="margin: 0 6px"></span>
                <BaseButton type="submit" class="btn-submit">
                  <i class="fa-solid fa-pencil"></i> Edit
                </BaseButton>
                <!-- <button class="btn-submit"><i class="fa-solid fa-pencil"></i> Edit</button> -->
              </td>
            </tr>
          </tbody>
        </table>

        <p v-if="filteredPhoneNumbers.length == 0" class="text-center" style="margin-top: 10rem">
          No records found!
        </p>
      </div>

      <p>
        Total Whitelisted Phone Number: <b>{{ filteredPhoneNumbers.length }}</b>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: [ButtonSubmit, ClipLoader, BaseButton, BaseInput],
  props: ["userData"],
  data: () => ({
    phoneLists: [],
    phoneNumber: "",
    updatePhoneNumber: "",
    phoneId: null,
    active_el: null,

    setColor: "#ff6a00",
    setSize: "50px",

    isLoading: true,

    sortBy: "",
    sortDesc: true,
  }),
  computed: {
    filteredPhoneNumbers() {
      return this.phoneLists.filter((item) =>
        item.PhoneNumberToWhitelist.toLowerCase().includes(this.phoneNumber.toLowerCase())
      )
    },
  },
  methods: {
    getWhiteListPhone(company_id) {
      api.get(`GetWhitelistByCompany/${company_id}`).then((response) => {
        this.isLoading = false
        this.phoneLists = response.data
      })
    },
    sort(sortBy) {
      if (sortBy === this.sortBy) {
        this.sortDesc = !this.sortDesc
      } else {
        this.sortBy = sortBy
        this.sortDesc = false
      }

      let sortedItems = this.phoneLists.slice()
      if (this.sortBy) {
        sortedItems.sort((a, b) => {
          let modifier = 1
          if (this.sortDesc) modifier = -1
          if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
          if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
          return 0
        })
      }
      this.phoneLists = sortedItems
    },
    insertWhiteListPhone() {
      // check if the phone number is already in the database
      const findPhoneNumber = this.phoneLists.some(
        (item) => item.PhoneNumberToWhitelist == this.phoneNumber
      )

      if (this.phoneNumber == "") {
        return Swal.fire({
          title: "",
          text: "Phone number is required.",
          icon: "warning",
        })
      } else if (this.hasLetter(this.phoneNumber)) {
        return Swal.fire({
          title: "",
          text: "Please enter a valid phone number",
          icon: "error",
        })
      } else if (findPhoneNumber) {
        return Swal.fire({
          title: "",
          text: "Phone number already exist",
          icon: "error",
        })
      } else {
        const data = {
          phoneNumber: this.phoneNumber,
          createdBy: "test",
          modifiedBy: "test",
          company_id: 32569,
        }
        api
          .post("insertWhiteList", data)
          .then((response) => {
            if (response.status == 200) {
              this.getWhiteListPhone(32569)
              this.phoneNumber = ""
              this.phoneId = null
              Swal.fire({
                title: "",
                text: "Data has been inserted.",
                icon: "success",
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    updateWhiteListPhone() {
      // check if the phone number is already in the database
      const findPhoneNumber = this.phoneLists.some(
        (item) => item.PhoneNumberToWhitelist == this.updatePhoneNumber
      )

      if (this.updatePhoneNumber == "") {
        return Swal.fire({
          title: "",
          text: "Phone number is required.",
          icon: "warning",
        })
      } else if (this.hasLetter(this.updatePhoneNumber)) {
        return Swal.fire({
          title: "",
          text: "Please enter a valid phone number",
          icon: "error",
        })
      } else if (findPhoneNumber) {
        return Swal.fire({
          title: "",
          text: "Phone number has not been modified",
          icon: "warning",
        })
      } else {
        const data = {
          phoneNumber: this.updatePhoneNumber,
        }
        api
          .post(`updateWhiteList/${this.phoneId}`, data)
          .then((response) => {
            if (response.status == 200) {
              this.active_el = null
              this.getWhiteListPhone(32569)
              this.updatePhoneNumber = ""
              this.phoneId = null
              Swal.fire({
                title: "",
                text: "Data has been updated.",
                icon: "success",
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    deleteWhiteListPhone(id, phoneNumber) {
      Swal.fire({
        title: "",
        html: `<p>Are you sure you want to delete phone number: <b>${phoneNumber}</b></p>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .post(`deleteWhiteList/${id}`)
            .then((response) => {
              if (response.status == 200) {
                this.active_el = null
                this.phoneNumber = ""
                this.updatePhoneNumber = ""
                this.phoneId = null
                this.getWhiteListPhone(32569)
                Swal.fire({
                  title: "",
                  text: "Data has been deleted.",
                  icon: "success",
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
    disableActiveClass() {
      this.phoneNumber = ""
      this.updatePhoneNumber = ""
      this.phoneId = null
      this.active_el = null
    },
    selectRow(id, phoneNumber) {
      this.phoneNumber = ""
      this.updatePhoneNumber = phoneNumber
      this.phoneId = id
      this.active_el = id
    },
    hasLetter(phoneNumber) {
      return /[a-zA-Z]/.test(phoneNumber)
    },
  },
  mounted() {
    this.getWhiteListPhone(32569)
  },
}
</script>

<style scoped>
.body-container {
  margin: 20px 0;
}
.arrow-up:before {
  content: "\25B2";
  font-size: 12px;
}
.arrow-down:before {
  content: "\25BC";
  font-size: 12px;
}
.active {
  background-color: #32bfb8 !important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button {
  width: 96px;
}

.btn-cancel {
  margin-left: 0;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-submit {
  position: static;
}
.phone-number-field {
  width: 97%;
  border-radius: 5px;
  border-color: #0e5071;
  padding: 5px;
}
.tableFixHead {
  overflow: auto;
  height: 600px;
}
.tableFixHead th {
  position: sticky;
  top: 0;
}
.tableFixHead .td1 {
  position: sticky;
  top: 40px;
  background-color: #fff !important;
}

.brandSection {
  /* width: 90vw; */
}
#table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 40%;
}

#table td,
#table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0e5071;
  color: white;
}
#table tr th:nth-of-type(1) {
  width: 10%;
}

#table tr th:nth-of-type(2) {
  width: 25%;
}

#table tr th:nth-of-type(3) {
  width: 35%;
}
.ml-1 {
  margin-left: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.font-size-14 {
  font-size: 14px;
}
.text-center {
  text-align: center;
}
.d-flex {
  display: flex;
}
.font-size-14 {
  font-size: 14px;
}
.text-right {
  text-align: right !important;
}
.whitelist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* table { display: block; } */
/* table {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
} */
</style>
